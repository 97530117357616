import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import { IntlProvider } from "react-intl";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

import LoadingFallback from "./components/LoadingFallback";
import ScrollToTop from "./components/ScrollToTop";
import ScrollToTopButton from "./components/ScrollToTopButton";
import ContactButtons from "./components/ContactButtons";
import Verify from "./pages/Verify/Verify";

const LoadingPage = React.lazy(() => import("./pages/LoadingPage"));
const ErrorPage = React.lazy(() => import("./pages/ErrorPage"));
const Home = React.lazy(() => import("./pages/Home"));
const Services = React.lazy(() => import("./pages/Services"));
const Photograph = React.lazy(() => import("./pages/Services/Photograph"));
const ProjectDetails = React.lazy(() =>
  import("./pages/Services/ProjectDetails")
);
const PhotoEditing = React.lazy(() => import("./pages/Services/PhotoEditing"));
const ContactPage = React.lazy(() => import("./pages/ContactPage"));
const AboutUsPage = React.lazy(() => import("./pages/AboutUsPage"));

function App() {
  const languageState = useSelector((state) => state.language);
  const { locale, messages } = languageState;
  const [checkVerify, setCheckVerify] = useState(false);

  useEffect(() => {
    Aos.init();
  }, []);

  // const RedirectToExternal = ({ url }) => {
  //   useEffect(() => {
  //     window.open(url, '_blank');
  //   }, [url]);

  //   return null;
  // };

  return (
    <IntlProvider messages={messages} locale={locale}>
      <div className="App">
        <Suspense fallback={<LoadingFallback />}>
          <Router>
            <ScrollToTop />
            {checkVerify === true ? (
              <Routes>
                <Route path="/" index element={<LoadingPage />} />
                <Route>
                  {/* <Route element={<SharedLayout />}> */}
                  <Route index path="/home" element={<Home />} />
                  <Route index path="/services" element={<Services />} />
                  <Route
                    index
                    path="/services/photograph"
                    element={<Photograph />}
                  />
                  <Route
                    index
                    path="/services/photograph/:hashtag/:projectName"
                    element={<ProjectDetails />}
                  />
                  <Route
                    index
                    path="/services/photo-editing"
                    element={<PhotoEditing />}
                  />
                  <Route index path="/about" element={<AboutUsPage />} />
                  <Route index path="/contact" element={<ContactPage />} />
                </Route>
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            ) : (
              <Routes>
                <Route
                  path="*"
                  element={
                    <Verify
                      checkVerify={checkVerify}
                      setCheckVerify={setCheckVerify}
                    />
                  }
                />
              </Routes>
            )}
            <ScrollToTopButton />
            <ContactButtons />
          </Router>
        </Suspense>
      </div>
    </IntlProvider>
  );
}

export default App;
