import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faFacebookMessenger } from "@fortawesome/free-brands-svg-icons";
import { Modal, Form, Input, message } from "antd";
import { MailOutlined } from "@ant-design/icons";
import emailjs from "emailjs-com";
import { FormattedMessage, useIntl } from "react-intl";

const MailButton = ({ isModalOpen, setIsModalOpen }) => {
  const intl = useIntl();
  const [form] = Form.useForm();

  const contactFormNameCheckBeforeSend = intl.formatMessage({
    id: "contactbuttons.check.name",
    defaultMessage: "Hãy nhập tên của Quý Khách!",
  });

  const contactFormNamePlaceHolder = intl.formatMessage({
    id: "contactbuttons.placeholder.name",
    defaultMessage: "Họ và tên của Quý Khách",
  });

  const contactFormPhoneCheckBeforeSend = intl.formatMessage({
    id: "contactbuttons.check.phone",
    defaultMessage: "Hãy kiểm tra lại số điện thoại của Quý Khách!",
  });

  const contactFormPhoneNumberPlaceHolder = intl.formatMessage({
    id: "contactbuttons.placeholder.phone",
    defaultMessage: "Số Điện Thoại của Quý Khách",
  });

  const contactFormMailCheckBeforeSend = intl.formatMessage({
    id: "contactbuttons.check.mail",
    defaultMessage: "Hãy kiểm tra email của Quý Khách!",
  });

  const contactFormMailPlaceHolder = intl.formatMessage({
    id: "contactbuttons.placeholder.mail",
    defaultMessage: "Email của Quý Khách",
  });

  const contactFormSentSuccess = intl.formatMessage({
    id: "contactbuttons.sent.success",
    defaultMessage: "Lời nhắn của Quý Khách đã được gửi!",
  });

  const contactFormSentFailed = intl.formatMessage({
    id: "contactbuttons.sent.failed",
    defaultMessage:
      "Đã có lỗi xảy ra. Lời nhắn của Quý Khách chưa được gửi, vui lòng thử lại!",
  });

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const sendEmail = (values) => {
    emailjs
      .send(
        "service_a43jyba",
        "template_gjxjenc",
        {
          name: values.name,
          phone: values.phone,
          email: values.email,
          message: values.message,
        },
        "GwIGz8ypqdB88nzqt"
      )
      .then(
        (result) => {
          message.success(contactFormSentSuccess);
          form.resetFields();
          setIsModalOpen(false);
        },
        (error) => {
          message.error(contactFormSentFailed);
        }
      );
  };

  return (
    <Modal
      title={<FormattedMessage id="contactbuttons.form.title" defaultMessage="Liên Hệ Với Chúng Tôi" />}
      open={isModalOpen}
      onOk={() => {
        form.submit();
      }}
      onCancel={handleCancel}
      okText={<FormattedMessage id="contactbuttons.form.button.sent" defaultMessage="Gửi" />}
      cancelText={<FormattedMessage id="contactbuttons.form.button.cancel" defaultMessage="Huỷ" />}
    >
      <Form form={form} ref={form} layout="vertical" onFinish={sendEmail}>
        <Form.Item
          name="name"
          label={<FormattedMessage id="contactbuttons.form.name" defaultMessage="Tên" />}
          validateTrigger="onSubmit"
          rules={[{ required: true, message: contactFormNameCheckBeforeSend }]}
        >
          <Input placeholder= {contactFormNamePlaceHolder} />
        </Form.Item>
        <Form.Item
          name="phone"
          label={<FormattedMessage id="contactbuttons.form.phone" defaultMessage="Số Điện Thoại" />}
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: contactFormPhoneCheckBeforeSend,
              pattern: /(0[3|5|7|8|9])+([0-9]{8})\b/,
            },
          ]}
        >
          <Input placeholder={contactFormPhoneNumberPlaceHolder} />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          validateTrigger="onSubmit"
          rules={[
            {
              required: true,
              message: contactFormMailCheckBeforeSend,
              pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
            },
          ]}
        >
          <Input type="email" placeholder={contactFormMailPlaceHolder} />
        </Form.Item>
        <Form.Item name="message" label={<FormattedMessage id="contactbuttons.form.message" defaultMessage="Lời Nhắn" />}>
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ContactButtons = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="fixed bottom-2 left-4 flex flex-col gap-0 z-50">
      <a
        href="https://m.me/wedevoto"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-blue-600 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 transition scale-75"
      >
        <FontAwesomeIcon icon={faFacebookMessenger} size="2x" />
      </a>
      <a
        href="https://zalo.me/0707006357"
        target="_blank"
        rel="noopener noreferrer"
        className="bg-green-600 text-white p-3 rounded-full shadow-lg hover:bg-green-700 transition scale-75"
      >
        {/* <FontAwesomeIcon icon={fa} size="2x" /> */}
        <svg
          className="w-8 h-8"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="100"
          height="100"
          viewBox="0 0 48 48"
        >
          <path
            fill="#2962ff"
            d="M15,36V6.827l-1.211-0.811C8.64,8.083,5,13.112,5,19v10c0,7.732,6.268,14,14,14h10	c4.722,0,8.883-2.348,11.417-5.931V36H15z"
          ></path>
          <path
            fill="#eee"
            d="M29,5H19c-1.845,0-3.601,0.366-5.214,1.014C10.453,9.25,8,14.528,8,19	c0,6.771,0.936,10.735,3.712,14.607c0.216,0.301,0.357,0.653,0.376,1.022c0.043,0.835-0.129,2.365-1.634,3.742	c-0.162,0.148-0.059,0.419,0.16,0.428c0.942,0.041,2.843-0.014,4.797-0.877c0.557-0.246,1.191-0.203,1.729,0.083	C20.453,39.764,24.333,40,28,40c4.676,0,9.339-1.04,12.417-2.916C42.038,34.799,43,32.014,43,29V19C43,11.268,36.732,5,29,5z"
          ></path>
          <path
            fill="#2962ff"
            d="M36.75,27C34.683,27,33,25.317,33,23.25s1.683-3.75,3.75-3.75s3.75,1.683,3.75,3.75	S38.817,27,36.75,27z M36.75,21c-1.24,0-2.25,1.01-2.25,2.25s1.01,2.25,2.25,2.25S39,24.49,39,23.25S37.99,21,36.75,21z"
          ></path>
          <path
            fill="#2962ff"
            d="M31.5,27h-1c-0.276,0-0.5-0.224-0.5-0.5V18h1.5V27z"
          ></path>
          <path
            fill="#2962ff"
            d="M27,19.75v0.519c-0.629-0.476-1.403-0.769-2.25-0.769c-2.067,0-3.75,1.683-3.75,3.75	S22.683,27,24.75,27c0.847,0,1.621-0.293,2.25-0.769V26.5c0,0.276,0.224,0.5,0.5,0.5h1v-7.25H27z M24.75,25.5	c-1.24,0-2.25-1.01-2.25-2.25S23.51,21,24.75,21S27,22.01,27,23.25S25.99,25.5,24.75,25.5z"
          ></path>
          <path
            fill="#2962ff"
            d="M21.25,18h-8v1.5h5.321L13,26h0.026c-0.163,0.211-0.276,0.463-0.276,0.75V27h7.5	c0.276,0,0.5-0.224,0.5-0.5v-1h-5.321L21,19h-0.026c0.163-0.211,0.276-0.463,0.276-0.75V18z"
          ></path>
        </svg>
      </a>
      <a
        href="tel:+84707006357"
        className="bg-red-600 text-white p-3 rounded-full shadow-lg hover:bg-red-700 transition scale-75 pulse-circle"
      >
        <FontAwesomeIcon icon={faPhone} size="2x" className="ringing-phone" />
      </a>
      <div
        className="bg-red-600 text-white p-3 flex justify-center items-center  rounded-full shadow-lg hover:bg-red-700 transition scale-75 pulse-circle"
        onClick={showModal}
      >
        <MailOutlined
          style={{ fontSize: "32px" }}
          className="text-white ringing-phone"
        />
      </div>

      <MailButton isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>
  );
};

export default ContactButtons;
