import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeNav: 1,
  activeNavSubItem: 1,
  activeNavChildItem: 1,
  activeMobileNav: false,
  
};

const navbarSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    updateActiveNav: (state, action) => {
      state.activeNav = action.payload;
    },
    updateActiveNavSubItem: (state, action) => {
      state.activeNavSubItem = action.payload;
    },
    updateActiveNavChildItem: (state, action) => {
      state.activeNavChildItem = action.payload;
    },
    SwitchActiveMobileNav: (state) => {
      state.activeMobileNav = !state.activeMobileNav
    }
  },
});

export default navbarSlice.reducer;
export const {
  updateActiveNav,
  updateActiveNavSubItem,
  updateActiveNavChildItem,
  SwitchActiveMobileNav,
} = navbarSlice.actions;
