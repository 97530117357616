import React, { useState } from "react";
import { Pagination } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveFilter,
  setSelectedProject,
} from "../../../../redux/features/ServicesSlice";
import { FormattedMessage } from "react-intl";

import the_song_1 from "../../../../assets/images/the-song-an-gia/0971.jpg";
import the_song_2 from "../../../../assets/images/the-song-an-gia/0125.jpg";
import the_song_3 from "../../../../assets/images/the-song-an-gia/0963.jpg";
import the_song_4 from "../../../../assets/images/the-song-an-gia/0073.jpg";
import the_song_5 from "../../../../assets/images/the-song-an-gia/0160.jpg";
import the_song_6 from "../../../../assets/images/the-song-an-gia/8559.jpg";
import the_song_7 from "../../../../assets/images/the-song-an-gia/0062.jpg";
import the_song_8 from "../../../../assets/images/the-song-an-gia/8186.jpg";
import the_song_9 from "../../../../assets/images/the-song-an-gia/8499.jpg";
import the_song_10 from "../../../../assets/images/the-song-an-gia/8508.jpg";
import the_song_11 from "../../../../assets/images/the-song-an-gia/8284.jpg";
import the_song_12 from "../../../../assets/images/the-song-an-gia/0699.jpg";
import the_song_13 from "../../../../assets/images/the-song-an-gia/0772.jpg";
import the_song_14 from "../../../../assets/images/the-song-an-gia/0576.jpg";
import the_song_15 from "../../../../assets/images/the-song-an-gia/0177.jpg";
import the_song_16 from "../../../../assets/images/the-song-an-gia/0670.jpg";

import thao_dien_green_1 from "../../../../assets/images/thao-dien-green-bm-windows/0033.jpg";
import thao_dien_green_2 from "../../../../assets/images/thao-dien-green-bm-windows/0844.jpg";
import thao_dien_green_3 from "../../../../assets/images/thao-dien-green-bm-windows/0104.jpg";
import thao_dien_green_4 from "../../../../assets/images/thao-dien-green-bm-windows/7424.jpg";
import thao_dien_green_5 from "../../../../assets/images/thao-dien-green-bm-windows/7464.jpg";
import thao_dien_green_6 from "../../../../assets/images/thao-dien-green-bm-windows/7494.jpg";
import thao_dien_green_7 from "../../../../assets/images/thao-dien-green-bm-windows/7709.jpg";
import thao_dien_green_8 from "../../../../assets/images/thao-dien-green-bm-windows/7479.jpg";
import thao_dien_green_9 from "../../../../assets/images/thao-dien-green-bm-windows/7448.jpg";
import thao_dien_green_10 from "../../../../assets/images/thao-dien-green-bm-windows/7671.jpg";
import thao_dien_green_11 from "../../../../assets/images/thao-dien-green-bm-windows/7700.jpg";
import thao_dien_green_12 from "../../../../assets/images/thao-dien-green-bm-windows/7724.jpg";
import thao_dien_green_13 from "../../../../assets/images/thao-dien-green-bm-windows/7652.jpg";

import charm_ruby_1 from "../../../../assets/images/charm-ruby-bm-windows/0499.jpg";
import charm_ruby_2 from "../../../../assets/images/charm-ruby-bm-windows/0504.jpg";
import charm_ruby_3 from "../../../../assets/images/charm-ruby-bm-windows/505.jpg";
import charm_ruby_4 from "../../../../assets/images/charm-ruby-bm-windows/0507.jpg";
import charm_ruby_5 from "../../../../assets/images/charm-ruby-bm-windows/0525.jpg";
import charm_ruby_6 from "../../../../assets/images/charm-ruby-bm-windows/8243.jpg";
import charm_ruby_7 from "../../../../assets/images/charm-ruby-bm-windows/8244.jpg";
import charm_ruby_8 from "../../../../assets/images/charm-ruby-bm-windows/8252.jpg";
import charm_ruby_9 from "../../../../assets/images/charm-ruby-bm-windows/8267.jpg";
import charm_ruby_10 from "../../../../assets/images/charm-ruby-bm-windows/8282.jpg";
import charm_ruby_11 from "../../../../assets/images/charm-ruby-bm-windows/8291.jpg";
import charm_ruby_12 from "../../../../assets/images/charm-ruby-bm-windows/8321.jpg";

import the_classia_1 from "../../../../assets/images/the-classia-khang-dien/0003.jpg";
import the_classia_2 from "../../../../assets/images/the-classia-khang-dien/0253.jpg";
import the_classia_3 from "../../../../assets/images/the-classia-khang-dien/0268.jpg";
import the_classia_4 from "../../../../assets/images/the-classia-khang-dien/0323.jpg";
import the_classia_5 from "../../../../assets/images/the-classia-khang-dien/0331.jpg";
import the_classia_6 from "../../../../assets/images/the-classia-khang-dien/0366.jpg";
import the_classia_7 from "../../../../assets/images/the-classia-khang-dien/0381.jpg";
import the_classia_8 from "../../../../assets/images/the-classia-khang-dien/0514.jpg";
import the_classia_9 from "../../../../assets/images/the-classia-khang-dien/0717.jpg";
import the_classia_10 from "../../../../assets/images/the-classia-khang-dien/0832.jpg";
import the_classia_11 from "../../../../assets/images/the-classia-khang-dien/0993.jpg";
import the_classia_12 from "../../../../assets/images/the-classia-khang-dien/1060.jpg";
import the_classia_13 from "../../../../assets/images/the-classia-khang-dien/1075.jpg";
import the_classia_14 from "../../../../assets/images/the-classia-khang-dien/3047.jpg";
import the_classia_15 from "../../../../assets/images/the-classia-khang-dien/3081.jpg";
import the_classia_16 from "../../../../assets/images/the-classia-khang-dien/3130.jpg";
import the_classia_17 from "../../../../assets/images/the-classia-khang-dien/6277.jpg";

import mct_1 from "../../../../assets/images/mct-gamuda-land/0712.jpeg";
import mct_2 from "../../../../assets/images/mct-gamuda-land/07121.jpeg";
import mct_3 from "../../../../assets/images/mct-gamuda-land/0696.jpeg";
import mct_4 from "../../../../assets/images/mct-gamuda-land/06961.jpeg";
import mct_5 from "../../../../assets/images/mct-gamuda-land/0210.jpeg";
import mct_6 from "../../../../assets/images/mct-gamuda-land/02101.jpeg";
import mct_7 from "../../../../assets/images/mct-gamuda-land/0266.jpeg";
import mct_8 from "../../../../assets/images/mct-gamuda-land/02661.jpeg";
import mct_9 from "../../../../assets/images/mct-gamuda-land/0292.jpg";
import mct_10 from "../../../../assets/images/mct-gamuda-land/0330.jpg";
import mct_11 from "../../../../assets/images/mct-gamuda-land/0449.jpg";
import mct_12 from "../../../../assets/images/mct-gamuda-land/0467.jpg";
import mct_13 from "../../../../assets/images/mct-gamuda-land/0550.jpg";

import artisan_park_1 from "../../../../assets/images/artisan-park-gamuda-land/0384.jpg";
import artisan_park_2 from "../../../../assets/images/artisan-park-gamuda-land/0397.jpg";
import artisan_park_3 from "../../../../assets/images/artisan-park-gamuda-land/9964.jpg";
import artisan_park_4 from "../../../../assets/images/artisan-park-gamuda-land/9967.jpg";
import artisan_park_5 from "../../../../assets/images/artisan-park-gamuda-land/9484.jpg";
import artisan_park_6 from "../../../../assets/images/artisan-park-gamuda-land/9475.jpg";
import artisan_park_7 from "../../../../assets/images/artisan-park-gamuda-land/9508.jpg";
import artisan_park_8 from "../../../../assets/images/artisan-park-gamuda-land/9511.jpg";
import artisan_park_9 from "../../../../assets/images/artisan-park-gamuda-land/9517.jpg";
import artisan_park_10 from "../../../../assets/images/artisan-park-gamuda-land/9520.jpg";
import artisan_park_11 from "../../../../assets/images/artisan-park-gamuda-land/9559.jpg";
import artisan_park_12 from "../../../../assets/images/artisan-park-gamuda-land/9613.jpg";
import artisan_park_13 from "../../../../assets/images/artisan-park-gamuda-land/9616.jpg";
import artisan_park_14 from "../../../../assets/images/artisan-park-gamuda-land/9015.jpg";
import artisan_park_15 from "../../../../assets/images/artisan-park-gamuda-land/9027.jpg";
import artisan_park_16 from "../../../../assets/images/artisan-park-gamuda-land/9037.jpg";
import artisan_park_17 from "../../../../assets/images/artisan-park-gamuda-land/9040.jpg";
import artisan_park_18 from "../../../../assets/images/artisan-park-gamuda-land/9051.jpg";
import artisan_park_19 from "../../../../assets/images/artisan-park-gamuda-land/9106.jpg";
import artisan_park_20 from "../../../../assets/images/artisan-park-gamuda-land/9132.jpg";
import artisan_park_21 from "../../../../assets/images/artisan-park-gamuda-land/9142.jpg";
import artisan_park_22 from "../../../../assets/images/artisan-park-gamuda-land/9196.jpg";
import artisan_park_23 from "../../../../assets/images/artisan-park-gamuda-land/9203.jpg";
import artisan_park_24 from "../../../../assets/images/artisan-park-gamuda-land/9218.jpg";
import artisan_park_25 from "../../../../assets/images/artisan-park-gamuda-land/9261.jpg";
import artisan_park_26 from "../../../../assets/images/artisan-park-gamuda-land/9262.jpg";
import artisan_park_27 from "../../../../assets/images/artisan-park-gamuda-land/9271.jpg";
import artisan_park_28 from "../../../../assets/images/artisan-park-gamuda-land/9293.jpg";
import artisan_park_29 from "../../../../assets/images/artisan-park-gamuda-land/9314.jpg";
import artisan_park_30 from "../../../../assets/images/artisan-park-gamuda-land/9344.jpg";
import artisan_park_31 from "../../../../assets/images/artisan-park-gamuda-land/9364.jpg";
import artisan_park_32 from "../../../../assets/images/artisan-park-gamuda-land/9365.jpg";
import artisan_park_33 from "../../../../assets/images/artisan-park-gamuda-land/9386.jpg";
import artisan_park_34 from "../../../../assets/images/artisan-park-gamuda-land/9411.jpg";
import artisan_park_35 from "../../../../assets/images/artisan-park-gamuda-land/9414.jpg";
import artisan_park_36 from "../../../../assets/images/artisan-park-gamuda-land/9430.jpg";
import artisan_park_37 from "../../../../assets/images/artisan-park-gamuda-land/9637.jpg";
import artisan_park_38 from "../../../../assets/images/artisan-park-gamuda-land/9640.jpg";
import artisan_park_39 from "../../../../assets/images/artisan-park-gamuda-land/9643.jpg";
import artisan_park_40 from "../../../../assets/images/artisan-park-gamuda-land/9733.jpg";
import artisan_park_41 from "../../../../assets/images/artisan-park-gamuda-land/9736.jpg";
import artisan_park_42 from "../../../../assets/images/artisan-park-gamuda-land/9750.jpg";
import artisan_park_43 from "../../../../assets/images/artisan-park-gamuda-land/9757.jpg";
import artisan_park_44 from "../../../../assets/images/artisan-park-gamuda-land/9775.jpg";
import artisan_park_45 from "../../../../assets/images/artisan-park-gamuda-land/9789.jpg";
import artisan_park_46 from "../../../../assets/images/artisan-park-gamuda-land/9802.jpg";
import artisan_park_47 from "../../../../assets/images/artisan-park-gamuda-land/9811.jpg";

import fiato_premier_1 from "../../../../assets/images/fiato-premier-thang-long-real/5554.jpg";
import fiato_premier_2 from "../../../../assets/images/fiato-premier-thang-long-real/5557.jpg";
import fiato_premier_3 from "../../../../assets/images/fiato-premier-thang-long-real/5504.jpg";
import fiato_premier_4 from "../../../../assets/images/fiato-premier-thang-long-real/5482.jpg";
import fiato_premier_5 from "../../../../assets/images/fiato-premier-thang-long-real/5579.jpg";
import fiato_premier_6 from "../../../../assets/images/fiato-premier-thang-long-real/6139.jpg";
import fiato_premier_7 from "../../../../assets/images/fiato-premier-thang-long-real/6098.jpg";
import fiato_premier_8 from "../../../../assets/images/fiato-premier-thang-long-real/6111.jpg";
import fiato_premier_9 from "../../../../assets/images/fiato-premier-thang-long-real/6121.jpg";
import fiato_premier_10 from "../../../../assets/images/fiato-premier-thang-long-real/5638.jpg";
import fiato_premier_11 from "../../../../assets/images/fiato-premier-thang-long-real/5656.jpg";
import fiato_premier_12 from "../../../../assets/images/fiato-premier-thang-long-real/5668.jpg";
import fiato_premier_13 from "../../../../assets/images/fiato-premier-thang-long-real/5674.jpg";
import fiato_premier_14 from "../../../../assets/images/fiato-premier-thang-long-real/5719.jpg";
import fiato_premier_15 from "../../../../assets/images/fiato-premier-thang-long-real/5748.jpg";
import fiato_premier_16 from "../../../../assets/images/fiato-premier-thang-long-real/5749.jpg";
import fiato_premier_17 from "../../../../assets/images/fiato-premier-thang-long-real/5761.jpg";
import fiato_premier_18 from "../../../../assets/images/fiato-premier-thang-long-real/5844.jpg";
import fiato_premier_19 from "../../../../assets/images/fiato-premier-thang-long-real/5868.jpg";
import fiato_premier_20 from "../../../../assets/images/fiato-premier-thang-long-real/6038.jpg";
import fiato_premier_21 from "../../../../assets/images/fiato-premier-thang-long-real/6053.jpg";
import fiato_premier_22 from "../../../../assets/images/fiato-premier-thang-long-real/9658.jpg";
import fiato_premier_23 from "../../../../assets/images/fiato-premier-thang-long-real/9237.jpg";
import fiato_premier_24 from "../../../../assets/images/fiato-premier-thang-long-real/9266.jpg";
import fiato_premier_25 from "../../../../assets/images/fiato-premier-thang-long-real/9332.jpg";
import fiato_premier_26 from "../../../../assets/images/fiato-premier-thang-long-real/9368.jpg";
import fiato_premier_27 from "../../../../assets/images/fiato-premier-thang-long-real/9414.jpg";
import fiato_premier_28 from "../../../../assets/images/fiato-premier-thang-long-real/9423.jpg";
import fiato_premier_29 from "../../../../assets/images/fiato-premier-thang-long-real/9432.jpg";
import fiato_premier_30 from "../../../../assets/images/fiato-premier-thang-long-real/9443.jpg";
import fiato_premier_31 from "../../../../assets/images/fiato-premier-thang-long-real/9467.jpg";
import fiato_premier_32 from "../../../../assets/images/fiato-premier-thang-long-real/9476.jpg";
import fiato_premier_33 from "../../../../assets/images/fiato-premier-thang-long-real/9509.jpg";
import fiato_premier_34 from "../../../../assets/images/fiato-premier-thang-long-real/9536.jpg";
import fiato_premier_35 from "../../../../assets/images/fiato-premier-thang-long-real/9560.jpg";
import fiato_premier_36 from "../../../../assets/images/fiato-premier-thang-long-real/9569.jpg";
import fiato_premier_37 from "../../../../assets/images/fiato-premier-thang-long-real/9586.jpg";
import fiato_premier_38 from "../../../../assets/images/fiato-premier-thang-long-real/9609.jpg";
import fiato_premier_39 from "../../../../assets/images/fiato-premier-thang-long-real/9635.jpg";
import fiato_premier_40 from "../../../../assets/images/fiato-premier-thang-long-real/9652.jpg";
import fiato_premier_41 from "../../../../assets/images/fiato-premier-thang-long-real/9662.jpg";
import fiato_premier_42 from "../../../../assets/images/fiato-premier-thang-long-real/9713.jpg";
import fiato_premier_43 from "../../../../assets/images/fiato-premier-thang-long-real/9732.jpg";

import vinhomes_central_park_1 from "../../../../assets/images/vinhomes-central-park-vingroup/1.jpg";
import vinhomes_central_park_2 from "../../../../assets/images/vinhomes-central-park-vingroup/2.jpg";
import vinhomes_central_park_3 from "../../../../assets/images/vinhomes-central-park-vingroup/3.jpg";
import vinhomes_central_park_4 from "../../../../assets/images/vinhomes-central-park-vingroup/4.jpg";
import vinhomes_central_park_5 from "../../../../assets/images/vinhomes-central-park-vingroup/5.jpg";
import vinhomes_central_park_6 from "../../../../assets/images/vinhomes-central-park-vingroup/6.jpg";
import vinhomes_central_park_7 from "../../../../assets/images/vinhomes-central-park-vingroup/7.jpg";
import vinhomes_central_park_8 from "../../../../assets/images/vinhomes-central-park-vingroup/8.jpg";
import vinhomes_central_park_9 from "../../../../assets/images/vinhomes-central-park-vingroup/9.jpg";
import vinhomes_central_park_10 from "../../../../assets/images/vinhomes-central-park-vingroup/10.jpg";
import vinhomes_central_park_11 from "../../../../assets/images/vinhomes-central-park-vingroup/11.jpg";
import vinhomes_central_park_12 from "../../../../assets/images/vinhomes-central-park-vingroup/12.jpg";
import vinhomes_central_park_13 from "../../../../assets/images/vinhomes-central-park-vingroup/13.jpg";
import vinhomes_central_park_14 from "../../../../assets/images/vinhomes-central-park-vingroup/14.jpg";
import vinhomes_central_park_15 from "../../../../assets/images/vinhomes-central-park-vingroup/15.jpg";
import vinhomes_central_park_16 from "../../../../assets/images/vinhomes-central-park-vingroup/16.jpg";
import vinhomes_central_park_17 from "../../../../assets/images/vinhomes-central-park-vingroup/17.jpg";
import vinhomes_central_park_18 from "../../../../assets/images/vinhomes-central-park-vingroup/18.jpg";
import vinhomes_central_park_19 from "../../../../assets/images/vinhomes-central-park-vingroup/19.jpg";
import vinhomes_central_park_20 from "../../../../assets/images/vinhomes-central-park-vingroup/20.jpg";
import vinhomes_central_park_21 from "../../../../assets/images/vinhomes-central-park-vingroup/21.jpg";
import vinhomes_central_park_22 from "../../../../assets/images/vinhomes-central-park-vingroup/22.jpg";
import vinhomes_central_park_23 from "../../../../assets/images/vinhomes-central-park-vingroup/23.jpg";
import vinhomes_central_park_24 from "../../../../assets/images/vinhomes-central-park-vingroup/24.jpg";
import vinhomes_central_park_25 from "../../../../assets/images/vinhomes-central-park-vingroup/25.jpg";
import vinhomes_central_park_26 from "../../../../assets/images/vinhomes-central-park-vingroup/26.jpg";
import vinhomes_central_park_27 from "../../../../assets/images/vinhomes-central-park-vingroup/27.jpg";
import vinhomes_central_park_28 from "../../../../assets/images/vinhomes-central-park-vingroup/28.jpg";
import vinhomes_central_park_29 from "../../../../assets/images/vinhomes-central-park-vingroup/29.jpg";
import vinhomes_central_park_30 from "../../../../assets/images/vinhomes-central-park-vingroup/30.jpg";
import vinhomes_central_park_31 from "../../../../assets/images/vinhomes-central-park-vingroup/31.jpg";
import vinhomes_central_park_32 from "../../../../assets/images/vinhomes-central-park-vingroup/32.jpg";
import vinhomes_central_park_33 from "../../../../assets/images/vinhomes-central-park-vingroup/33.jpg";
import vinhomes_central_park_34 from "../../../../assets/images/vinhomes-central-park-vingroup/34.jpg";
import vinhomes_central_park_35 from "../../../../assets/images/vinhomes-central-park-vingroup/35.jpg";
import vinhomes_central_park_36 from "../../../../assets/images/vinhomes-central-park-vingroup/36.jpg";
import vinhomes_central_park_37 from "../../../../assets/images/vinhomes-central-park-vingroup/37.jpg";
import vinhomes_central_park_38 from "../../../../assets/images/vinhomes-central-park-vingroup/38.jpg";
import vinhomes_central_park_39 from "../../../../assets/images/vinhomes-central-park-vingroup/39.jpg";
import vinhomes_central_park_40 from "../../../../assets/images/vinhomes-central-park-vingroup/40.jpg";
import vinhomes_central_park_41 from "../../../../assets/images/vinhomes-central-park-vingroup/41.jpg";
import vinhomes_central_park_42 from "../../../../assets/images/vinhomes-central-park-vingroup/42.jpg";
import vinhomes_central_park_43 from "../../../../assets/images/vinhomes-central-park-vingroup/43.jpg";
import vinhomes_central_park_44 from "../../../../assets/images/vinhomes-central-park-vingroup/44.jpg";
import vinhomes_central_park_45 from "../../../../assets/images/vinhomes-central-park-vingroup/45.jpg";
import vinhomes_central_park_46 from "../../../../assets/images/vinhomes-central-park-vingroup/46.jpg";
import vinhomes_central_park_47 from "../../../../assets/images/vinhomes-central-park-vingroup/47.jpg";
import vinhomes_central_park_48 from "../../../../assets/images/vinhomes-central-park-vingroup/48.jpg";
import vinhomes_central_park_49 from "../../../../assets/images/vinhomes-central-park-vingroup/49.jpg";
import vinhomes_central_park_50 from "../../../../assets/images/vinhomes-central-park-vingroup/50.jpg";
import vinhomes_central_park_51 from "../../../../assets/images/vinhomes-central-park-vingroup/51.jpg";
import vinhomes_central_park_52 from "../../../../assets/images/vinhomes-central-park-vingroup/52.jpg";
import vinhomes_central_park_53 from "../../../../assets/images/vinhomes-central-park-vingroup/53.jpg";
import vinhomes_central_park_54 from "../../../../assets/images/vinhomes-central-park-vingroup/54.jpg";
import vinhomes_central_park_55 from "../../../../assets/images/vinhomes-central-park-vingroup/55.jpg";
import vinhomes_central_park_56 from "../../../../assets/images/vinhomes-central-park-vingroup/56.jpg";
import vinhomes_central_park_57 from "../../../../assets/images/vinhomes-central-park-vingroup/57.jpg";
import vinhomes_central_park_58 from "../../../../assets/images/vinhomes-central-park-vingroup/58.jpg";
import vinhomes_central_park_59 from "../../../../assets/images/vinhomes-central-park-vingroup/59.jpg";
import vinhomes_central_park_60 from "../../../../assets/images/vinhomes-central-park-vingroup/60.jpg";
import vinhomes_central_park_61 from "../../../../assets/images/vinhomes-central-park-vingroup/61.jpg";
import vinhomes_central_park_62 from "../../../../assets/images/vinhomes-central-park-vingroup/62.jpg";
import vinhomes_central_park_63 from "../../../../assets/images/vinhomes-central-park-vingroup/63.jpg";
import vinhomes_central_park_64 from "../../../../assets/images/vinhomes-central-park-vingroup/64.jpg";
import vinhomes_central_park_65 from "../../../../assets/images/vinhomes-central-park-vingroup/65.jpg";
import vinhomes_central_park_66 from "../../../../assets/images/vinhomes-central-park-vingroup/66.jpg";
import vinhomes_central_park_67 from "../../../../assets/images/vinhomes-central-park-vingroup/67.jpg";
import vinhomes_central_park_68 from "../../../../assets/images/vinhomes-central-park-vingroup/68.jpg";
import vinhomes_central_park_69 from "../../../../assets/images/vinhomes-central-park-vingroup/69.jpg";
import vinhomes_central_park_70 from "../../../../assets/images/vinhomes-central-park-vingroup/70.jpg";
import vinhomes_central_park_71 from "../../../../assets/images/vinhomes-central-park-vingroup/71.jpg";
import vinhomes_central_park_72 from "../../../../assets/images/vinhomes-central-park-vingroup/72.jpg";
import vinhomes_central_park_73 from "../../../../assets/images/vinhomes-central-park-vingroup/73.jpg";
import vinhomes_central_park_74 from "../../../../assets/images/vinhomes-central-park-vingroup/74.jpg";
import vinhomes_central_park_75 from "../../../../assets/images/vinhomes-central-park-vingroup/75.jpg";
import vinhomes_central_park_76 from "../../../../assets/images/vinhomes-central-park-vingroup/76.jpg";
import vinhomes_central_park_77 from "../../../../assets/images/vinhomes-central-park-vingroup/77.jpg";
import vinhomes_central_park_78 from "../../../../assets/images/vinhomes-central-park-vingroup/78.jpg";
import vinhomes_central_park_79 from "../../../../assets/images/vinhomes-central-park-vingroup/79.jpg";
import vinhomes_central_park_80 from "../../../../assets/images/vinhomes-central-park-vingroup/80.jpg";

import vinhomes_grand_park_1 from "../../../../assets/images/vinhomes-grand-park-vingroup/1.jpeg";
import vinhomes_grand_park_2 from "../../../../assets/images/vinhomes-grand-park-vingroup/2.jpeg";
import vinhomes_grand_park_3 from "../../../../assets/images/vinhomes-grand-park-vingroup/3.jpeg";
import vinhomes_grand_park_4 from "../../../../assets/images/vinhomes-grand-park-vingroup/4.jpeg";
import vinhomes_grand_park_5 from "../../../../assets/images/vinhomes-grand-park-vingroup/5.jpeg";
import vinhomes_grand_park_6 from "../../../../assets/images/vinhomes-grand-park-vingroup/6.jpeg";
import vinhomes_grand_park_7 from "../../../../assets/images/vinhomes-grand-park-vingroup/7.jpeg";
import vinhomes_grand_park_8 from "../../../../assets/images/vinhomes-grand-park-vingroup/8.jpeg";
import vinhomes_grand_park_9 from "../../../../assets/images/vinhomes-grand-park-vingroup/9.jpeg";
import vinhomes_grand_park_10 from "../../../../assets/images/vinhomes-grand-park-vingroup/10.jpeg";
import vinhomes_grand_park_11 from "../../../../assets/images/vinhomes-grand-park-vingroup/11.jpeg";
import vinhomes_grand_park_12 from "../../../../assets/images/vinhomes-grand-park-vingroup/12.jpeg";
import vinhomes_grand_park_13 from "../../../../assets/images/vinhomes-grand-park-vingroup/13.jpeg";
import vinhomes_grand_park_14 from "../../../../assets/images/vinhomes-grand-park-vingroup/14.jpeg";
import vinhomes_grand_park_15 from "../../../../assets/images/vinhomes-grand-park-vingroup/15.jpeg";
import vinhomes_grand_park_16 from "../../../../assets/images/vinhomes-grand-park-vingroup/16.jpeg";
import vinhomes_grand_park_17 from "../../../../assets/images/vinhomes-grand-park-vingroup/17.jpeg";
import vinhomes_grand_park_18 from "../../../../assets/images/vinhomes-grand-park-vingroup/18.jpeg";
import vinhomes_grand_park_19 from "../../../../assets/images/vinhomes-grand-park-vingroup/19.jpeg";
import vinhomes_grand_park_20 from "../../../../assets/images/vinhomes-grand-park-vingroup/20.jpeg";
import vinhomes_grand_park_21 from "../../../../assets/images/vinhomes-grand-park-vingroup/21.jpeg";
import vinhomes_grand_park_22 from "../../../../assets/images/vinhomes-grand-park-vingroup/22.jpeg";
import vinhomes_grand_park_23 from "../../../../assets/images/vinhomes-grand-park-vingroup/23.jpeg";
import vinhomes_grand_park_24 from "../../../../assets/images/vinhomes-grand-park-vingroup/24.jpeg";
import vinhomes_grand_park_25 from "../../../../assets/images/vinhomes-grand-park-vingroup/25.jpeg";
import vinhomes_grand_park_26 from "../../../../assets/images/vinhomes-grand-park-vingroup/26.jpeg";
import vinhomes_grand_park_27 from "../../../../assets/images/vinhomes-grand-park-vingroup/27.jpeg";
import vinhomes_grand_park_28 from "../../../../assets/images/vinhomes-grand-park-vingroup/28.jpeg";
import vinhomes_grand_park_29 from "../../../../assets/images/vinhomes-grand-park-vingroup/29.jpeg";
import vinhomes_grand_park_30 from "../../../../assets/images/vinhomes-grand-park-vingroup/30.jpeg";
import vinhomes_grand_park_31 from "../../../../assets/images/vinhomes-grand-park-vingroup/31.jpeg";
import vinhomes_grand_park_32 from "../../../../assets/images/vinhomes-grand-park-vingroup/32.jpeg";
import vinhomes_grand_park_33 from "../../../../assets/images/vinhomes-grand-park-vingroup/33.jpeg";
import vinhomes_grand_park_34 from "../../../../assets/images/vinhomes-grand-park-vingroup/34.jpeg";
import vinhomes_grand_park_35 from "../../../../assets/images/vinhomes-grand-park-vingroup/35.jpeg";
import vinhomes_grand_park_36 from "../../../../assets/images/vinhomes-grand-park-vingroup/36.jpeg";
import vinhomes_grand_park_37 from "../../../../assets/images/vinhomes-grand-park-vingroup/37.jpeg";

import grandworld_phu_quoc_1 from "../../../../assets/images/grandworld-phu-quoc-vingroup/1.jpg";
import grandworld_phu_quoc_2 from "../../../../assets/images/grandworld-phu-quoc-vingroup/2.jpg";
import grandworld_phu_quoc_3 from "../../../../assets/images/grandworld-phu-quoc-vingroup/3.jpg";
import grandworld_phu_quoc_4 from "../../../../assets/images/grandworld-phu-quoc-vingroup/4.jpg";
import grandworld_phu_quoc_5 from "../../../../assets/images/grandworld-phu-quoc-vingroup/5.jpg";
import grandworld_phu_quoc_6 from "../../../../assets/images/grandworld-phu-quoc-vingroup/6.jpg";
import grandworld_phu_quoc_7 from "../../../../assets/images/grandworld-phu-quoc-vingroup/7.jpg";
import grandworld_phu_quoc_8 from "../../../../assets/images/grandworld-phu-quoc-vingroup/8.jpg";
import grandworld_phu_quoc_9 from "../../../../assets/images/grandworld-phu-quoc-vingroup/9.jpg";
import grandworld_phu_quoc_10 from "../../../../assets/images/grandworld-phu-quoc-vingroup/10.jpg";
import grandworld_phu_quoc_11 from "../../../../assets/images/grandworld-phu-quoc-vingroup/11.jpg";
import grandworld_phu_quoc_12 from "../../../../assets/images/grandworld-phu-quoc-vingroup/12.jpg";
import grandworld_phu_quoc_13 from "../../../../assets/images/grandworld-phu-quoc-vingroup/13.jpg";
import grandworld_phu_quoc_14 from "../../../../assets/images/grandworld-phu-quoc-vingroup/14.jpg";
import grandworld_phu_quoc_15 from "../../../../assets/images/grandworld-phu-quoc-vingroup/15.jpg";
import grandworld_phu_quoc_16 from "../../../../assets/images/grandworld-phu-quoc-vingroup/16.jpg";
import grandworld_phu_quoc_17 from "../../../../assets/images/grandworld-phu-quoc-vingroup/17.jpg";
import grandworld_phu_quoc_18 from "../../../../assets/images/grandworld-phu-quoc-vingroup/18.jpg";
import grandworld_phu_quoc_19 from "../../../../assets/images/grandworld-phu-quoc-vingroup/19.jpg";
import grandworld_phu_quoc_20 from "../../../../assets/images/grandworld-phu-quoc-vingroup/20.jpg";
import grandworld_phu_quoc_21 from "../../../../assets/images/grandworld-phu-quoc-vingroup/21.jpg";
import grandworld_phu_quoc_22 from "../../../../assets/images/grandworld-phu-quoc-vingroup/22.jpg";
import grandworld_phu_quoc_23 from "../../../../assets/images/grandworld-phu-quoc-vingroup/23.jpg";
import grandworld_phu_quoc_24 from "../../../../assets/images/grandworld-phu-quoc-vingroup/24.jpg";
import grandworld_phu_quoc_25 from "../../../../assets/images/grandworld-phu-quoc-vingroup/25.jpg";
import grandworld_phu_quoc_26 from "../../../../assets/images/grandworld-phu-quoc-vingroup/26.jpg";
import grandworld_phu_quoc_27 from "../../../../assets/images/grandworld-phu-quoc-vingroup/27.jpg";
import grandworld_phu_quoc_28 from "../../../../assets/images/grandworld-phu-quoc-vingroup/28.jpg";
import grandworld_phu_quoc_29 from "../../../../assets/images/grandworld-phu-quoc-vingroup/29.jpg";
import grandworld_phu_quoc_30 from "../../../../assets/images/grandworld-phu-quoc-vingroup/30.jpg";
import grandworld_phu_quoc_31 from "../../../../assets/images/grandworld-phu-quoc-vingroup/31.jpg";
import grandworld_phu_quoc_32 from "../../../../assets/images/grandworld-phu-quoc-vingroup/32.jpg";
import grandworld_phu_quoc_33 from "../../../../assets/images/grandworld-phu-quoc-vingroup/33.jpg";
import grandworld_phu_quoc_34 from "../../../../assets/images/grandworld-phu-quoc-vingroup/34.jpg";
import grandworld_phu_quoc_35 from "../../../../assets/images/grandworld-phu-quoc-vingroup/35.jpg";
import grandworld_phu_quoc_36 from "../../../../assets/images/grandworld-phu-quoc-vingroup/36.jpg";
import grandworld_phu_quoc_37 from "../../../../assets/images/grandworld-phu-quoc-vingroup/37.jpg";
import grandworld_phu_quoc_38 from "../../../../assets/images/grandworld-phu-quoc-vingroup/38.jpg";
import grandworld_phu_quoc_39 from "../../../../assets/images/grandworld-phu-quoc-vingroup/39.jpg";
import grandworld_phu_quoc_40 from "../../../../assets/images/grandworld-phu-quoc-vingroup/40.jpg";

import dak_srong_3a_3b_1 from "../../../../assets/images/dak-srong-3a-3b-bitexcopower/1.jpeg";
import dak_srong_3a_3b_2 from "../../../../assets/images/dak-srong-3a-3b-bitexcopower/2.jpeg";
import dak_srong_3a_3b_3 from "../../../../assets/images/dak-srong-3a-3b-bitexcopower/3.jpeg";
import dak_srong_3a_3b_4 from "../../../../assets/images/dak-srong-3a-3b-bitexcopower/4.jpeg";
import dak_srong_3a_3b_5 from "../../../../assets/images/dak-srong-3a-3b-bitexcopower/5.jpeg";
import dak_srong_3a_3b_6 from "../../../../assets/images/dak-srong-3a-3b-bitexcopower/6.jpeg";
import dak_srong_3a_3b_7 from "../../../../assets/images/dak-srong-3a-3b-bitexcopower/7.jpeg";
import dak_srong_3a_3b_8 from "../../../../assets/images/dak-srong-3a-3b-bitexcopower/8.jpeg";
import dak_srong_3a_3b_9 from "../../../../assets/images/dak-srong-3a-3b-bitexcopower/9.jpeg";
import dak_srong_3a_3b_10 from "../../../../assets/images/dak-srong-3a-3b-bitexcopower/10.jpeg";
import dak_srong_3a_3b_11 from "../../../../assets/images/dak-srong-3a-3b-bitexcopower/11.jpeg";
import dak_srong_3a_3b_12 from "../../../../assets/images/dak-srong-3a-3b-bitexcopower/12.jpeg";
import dak_srong_3a_3b_13 from "../../../../assets/images/dak-srong-3a-3b-bitexcopower/13.jpeg";
import dak_srong_3a_3b_14 from "../../../../assets/images/dak-srong-3a-3b-bitexcopower/14.jpeg";
import dak_srong_3a_3b_15 from "../../../../assets/images/dak-srong-3a-3b-bitexcopower/15.jpeg";

import loc_ninh_5_1 from "../../../../assets/images/loc-ninh-5-bitexcopower/1.jpg";
import loc_ninh_5_2 from "../../../../assets/images/loc-ninh-5-bitexcopower/2.jpg";
import loc_ninh_5_3 from "../../../../assets/images/loc-ninh-5-bitexcopower/3.jpg";
import loc_ninh_5_4 from "../../../../assets/images/loc-ninh-5-bitexcopower/4.jpg";
import loc_ninh_5_5 from "../../../../assets/images/loc-ninh-5-bitexcopower/5.jpg";
import loc_ninh_5_6 from "../../../../assets/images/loc-ninh-5-bitexcopower/6.jpg";
import loc_ninh_5_7 from "../../../../assets/images/loc-ninh-5-bitexcopower/7.jpg";
import loc_ninh_5_8 from "../../../../assets/images/loc-ninh-5-bitexcopower/8.jpg";
import loc_ninh_5_9 from "../../../../assets/images/loc-ninh-5-bitexcopower/9.jpg";
import loc_ninh_5_10 from "../../../../assets/images/loc-ninh-5-bitexcopower/10.jpg";
import loc_ninh_5_11 from "../../../../assets/images/loc-ninh-5-bitexcopower/11.jpg";
import loc_ninh_5_12 from "../../../../assets/images/loc-ninh-5-bitexcopower/12.jpg";
import loc_ninh_5_13 from "../../../../assets/images/loc-ninh-5-bitexcopower/13.jpg";
import loc_ninh_5_14 from "../../../../assets/images/loc-ninh-5-bitexcopower/14.jpg";
import loc_ninh_5_15 from "../../../../assets/images/loc-ninh-5-bitexcopower/15.jpg";
import loc_ninh_5_16 from "../../../../assets/images/loc-ninh-5-bitexcopower/16.jpg";

import bvdk_tinh_tien_giang_1 from "../../../../assets/images/bvdk-tinh-tiengiang-bm-windows/1.jpg";
import bvdk_tinh_tien_giang_2 from "../../../../assets/images/bvdk-tinh-tiengiang-bm-windows/2.jpg";
import bvdk_tinh_tien_giang_3 from "../../../../assets/images/bvdk-tinh-tiengiang-bm-windows/3.jpg";
import bvdk_tinh_tien_giang_4 from "../../../../assets/images/bvdk-tinh-tiengiang-bm-windows/4.jpg";
import bvdk_tinh_tien_giang_5 from "../../../../assets/images/bvdk-tinh-tiengiang-bm-windows/5.jpg";
import bvdk_tinh_tien_giang_6 from "../../../../assets/images/bvdk-tinh-tiengiang-bm-windows/6.jpg";
import bvdk_tinh_tien_giang_7 from "../../../../assets/images/bvdk-tinh-tiengiang-bm-windows/7.jpg";
import bvdk_tinh_tien_giang_8 from "../../../../assets/images/bvdk-tinh-tiengiang-bm-windows/8.jpg";
import bvdk_tinh_tien_giang_9 from "../../../../assets/images/bvdk-tinh-tiengiang-bm-windows/9.jpg";
import bvdk_tinh_tien_giang_10 from "../../../../assets/images/bvdk-tinh-tiengiang-bm-windows/10.jpg";
import bvdk_tinh_tien_giang_11 from "../../../../assets/images/bvdk-tinh-tiengiang-bm-windows/11.jpg";
import bvdk_tinh_tien_giang_12 from "../../../../assets/images/bvdk-tinh-tiengiang-bm-windows/12.jpg";
import bvdk_tinh_tien_giang_13 from "../../../../assets/images/bvdk-tinh-tiengiang-bm-windows/13.jpg";
import bvdk_tinh_tien_giang_14 from "../../../../assets/images/bvdk-tinh-tiengiang-bm-windows/14.jpg";
import bvdk_tinh_tien_giang_15 from "../../../../assets/images/bvdk-tinh-tiengiang-bm-windows/15.jpg";
import bvdk_tinh_tien_giang_16 from "../../../../assets/images/bvdk-tinh-tiengiang-bm-windows/16.jpg";
import bvdk_tinh_tien_giang_17 from "../../../../assets/images/bvdk-tinh-tiengiang-bm-windows/17.jpg";
import bvdk_tinh_tien_giang_18 from "../../../../assets/images/bvdk-tinh-tiengiang-bm-windows/18.jpg";

import nha_may_bm_windows_1 from "../../../../assets/images/nha-may-bm-windows/1.jpg";
import nha_may_bm_windows_2 from "../../../../assets/images/nha-may-bm-windows/2.jpg";
import nha_may_bm_windows_3 from "../../../../assets/images/nha-may-bm-windows/3.jpg";
import nha_may_bm_windows_4 from "../../../../assets/images/nha-may-bm-windows/4.jpg";
import nha_may_bm_windows_5 from "../../../../assets/images/nha-may-bm-windows/5.jpg";
import nha_may_bm_windows_6 from "../../../../assets/images/nha-may-bm-windows/6.jpg";
import nha_may_bm_windows_7 from "../../../../assets/images/nha-may-bm-windows/7.jpg";
import nha_may_bm_windows_8 from "../../../../assets/images/nha-may-bm-windows/8.jpg";
import nha_may_bm_windows_9 from "../../../../assets/images/nha-may-bm-windows/9.jpg";
import nha_may_bm_windows_10 from "../../../../assets/images/nha-may-bm-windows/10.jpg";
import nha_may_bm_windows_11 from "../../../../assets/images/nha-may-bm-windows/11.jpg";
import nha_may_bm_windows_12 from "../../../../assets/images/nha-may-bm-windows/12.jpg";
import nha_may_bm_windows_13 from "../../../../assets/images/nha-may-bm-windows/13.jpg";
import nha_may_bm_windows_14 from "../../../../assets/images/nha-may-bm-windows/14.jpg";
import nha_may_bm_windows_15 from "../../../../assets/images/nha-may-bm-windows/15.jpg";
import nha_may_bm_windows_16 from "../../../../assets/images/nha-may-bm-windows/16.jpg";
import nha_may_bm_windows_17 from "../../../../assets/images/nha-may-bm-windows/17.jpg";
import nha_may_bm_windows_18 from "../../../../assets/images/nha-may-bm-windows/18.jpg";
import nha_may_bm_windows_19 from "../../../../assets/images/nha-may-bm-windows/19.jpg";
import nha_may_bm_windows_20 from "../../../../assets/images/nha-may-bm-windows/20.jpg";
import nha_may_bm_windows_21 from "../../../../assets/images/nha-may-bm-windows/21.jpg";
import nha_may_bm_windows_22 from "../../../../assets/images/nha-may-bm-windows/22.jpg";
import nha_may_bm_windows_23 from "../../../../assets/images/nha-may-bm-windows/23.jpg";
import nha_may_bm_windows_24 from "../../../../assets/images/nha-may-bm-windows/24.jpg";
import nha_may_bm_windows_25 from "../../../../assets/images/nha-may-bm-windows/25.jpg";
import nha_may_bm_windows_26 from "../../../../assets/images/nha-may-bm-windows/26.jpg";
import nha_may_bm_windows_27 from "../../../../assets/images/nha-may-bm-windows/27.jpg";
import nha_may_bm_windows_28 from "../../../../assets/images/nha-may-bm-windows/28.jpg";
import nha_may_bm_windows_29 from "../../../../assets/images/nha-may-bm-windows/29.jpg";
import nha_may_bm_windows_30 from "../../../../assets/images/nha-may-bm-windows/30.jpg";

import techcombank_leduan_1 from "../../../../assets/images/techcombank-leduan-bm-windows/1.jpeg";
import techcombank_leduan_2 from "../../../../assets/images/techcombank-leduan-bm-windows/2.jpeg";
import techcombank_leduan_3 from "../../../../assets/images/techcombank-leduan-bm-windows/3.jpeg";
import techcombank_leduan_4 from "../../../../assets/images/techcombank-leduan-bm-windows/4.jpeg";
import techcombank_leduan_5 from "../../../../assets/images/techcombank-leduan-bm-windows/5.jpeg";
import techcombank_leduan_6 from "../../../../assets/images/techcombank-leduan-bm-windows/6.jpeg";
import techcombank_leduan_7 from "../../../../assets/images/techcombank-leduan-bm-windows/7.jpeg";
import techcombank_leduan_8 from "../../../../assets/images/techcombank-leduan-bm-windows/8.jpeg";
import techcombank_leduan_9 from "../../../../assets/images/techcombank-leduan-bm-windows/9.jpeg";
import techcombank_leduan_10 from "../../../../assets/images/techcombank-leduan-bm-windows/10.jpeg";
import techcombank_leduan_11 from "../../../../assets/images/techcombank-leduan-bm-windows/11.jpeg";
import techcombank_leduan_12 from "../../../../assets/images/techcombank-leduan-bm-windows/12.jpeg";
import techcombank_leduan_13 from "../../../../assets/images/techcombank-leduan-bm-windows/13.jpeg";
import techcombank_leduan_14 from "../../../../assets/images/techcombank-leduan-bm-windows/14.jpeg";
import techcombank_leduan_15 from "../../../../assets/images/techcombank-leduan-bm-windows/15.jpeg";

import nmdg_nhon_hoi_1 from "../../../../assets/images/nmdg-nhon-hoi-xuan-cau-group/1.jpg";
import nmdg_nhon_hoi_2 from "../../../../assets/images/nmdg-nhon-hoi-xuan-cau-group/2.jpg";
import nmdg_nhon_hoi_3 from "../../../../assets/images/nmdg-nhon-hoi-xuan-cau-group/3.jpg";
import nmdg_nhon_hoi_4 from "../../../../assets/images/nmdg-nhon-hoi-xuan-cau-group/4.jpg";
import nmdg_nhon_hoi_5 from "../../../../assets/images/nmdg-nhon-hoi-xuan-cau-group/5.jpg";
import nmdg_nhon_hoi_6 from "../../../../assets/images/nmdg-nhon-hoi-xuan-cau-group/6.jpg";
import nmdg_nhon_hoi_7 from "../../../../assets/images/nmdg-nhon-hoi-xuan-cau-group/7.jpg";
import nmdg_nhon_hoi_8 from "../../../../assets/images/nmdg-nhon-hoi-xuan-cau-group/8.jpg";
import nmdg_nhon_hoi_9 from "../../../../assets/images/nmdg-nhon-hoi-xuan-cau-group/9.jpg";
import nmdg_nhon_hoi_10 from "../../../../assets/images/nmdg-nhon-hoi-xuan-cau-group/10.jpg";
import nmdg_nhon_hoi_11 from "../../../../assets/images/nmdg-nhon-hoi-xuan-cau-group/11.jpg";
import nmdg_nhon_hoi_12 from "../../../../assets/images/nmdg-nhon-hoi-xuan-cau-group/12.jpg";
import nmdg_nhon_hoi_13 from "../../../../assets/images/nmdg-nhon-hoi-xuan-cau-group/13.jpg";
import nmdg_nhon_hoi_14 from "../../../../assets/images/nmdg-nhon-hoi-xuan-cau-group/14.jpg";

import nmdg_st7_1 from "../../../../assets/images/nmdg-st7-xuan-cau-group/1.jpeg";
import nmdg_st7_2 from "../../../../assets/images/nmdg-st7-xuan-cau-group/2.jpeg";
import nmdg_st7_3 from "../../../../assets/images/nmdg-st7-xuan-cau-group/3.jpeg";
import nmdg_st7_4 from "../../../../assets/images/nmdg-st7-xuan-cau-group/4.jpeg";
import nmdg_st7_5 from "../../../../assets/images/nmdg-st7-xuan-cau-group/5.jpeg";
import nmdg_st7_6 from "../../../../assets/images/nmdg-st7-xuan-cau-group/6.jpeg";
import nmdg_st7_7 from "../../../../assets/images/nmdg-st7-xuan-cau-group/7.jpeg";
import nmdg_st7_8 from "../../../../assets/images/nmdg-st7-xuan-cau-group/8.jpeg";
import nmdg_st7_9 from "../../../../assets/images/nmdg-st7-xuan-cau-group/9.jpeg";
import nmdg_st7_10 from "../../../../assets/images/nmdg-st7-xuan-cau-group/10.jpeg";
import nmdg_st7_11 from "../../../../assets/images/nmdg-st7-xuan-cau-group/11.jpeg";
import nmdg_st7_12 from "../../../../assets/images/nmdg-st7-xuan-cau-group/12.jpeg";
import nmdg_st7_13 from "../../../../assets/images/nmdg-st7-xuan-cau-group/13.jpeg";
import nmdg_st7_14 from "../../../../assets/images/nmdg-st7-xuan-cau-group/14.jpeg";
import nmdg_st7_15 from "../../../../assets/images/nmdg-st7-xuan-cau-group/15.jpeg";
import nmdg_st7_16 from "../../../../assets/images/nmdg-st7-xuan-cau-group/16.jpeg";
import nmdg_st7_17 from "../../../../assets/images/nmdg-st7-xuan-cau-group/17.jpeg";
import nmdg_st7_18 from "../../../../assets/images/nmdg-st7-xuan-cau-group/18.jpeg";
import nmdg_st7_19 from "../../../../assets/images/nmdg-st7-xuan-cau-group/19.jpeg";
import nmdg_st7_20 from "../../../../assets/images/nmdg-st7-xuan-cau-group/20.jpeg";
import nmdg_st7_21 from "../../../../assets/images/nmdg-st7-xuan-cau-group/21.jpeg";
import nmdg_st7_22 from "../../../../assets/images/nmdg-st7-xuan-cau-group/22.jpeg";

import ben_tau_con_dao_1 from "../../../../assets/images/ben-tau-con-dao-alcorest/1.jpg";
import ben_tau_con_dao_2 from "../../../../assets/images/ben-tau-con-dao-alcorest/2.jpg";
import ben_tau_con_dao_3 from "../../../../assets/images/ben-tau-con-dao-alcorest/3.jpg";
import ben_tau_con_dao_4 from "../../../../assets/images/ben-tau-con-dao-alcorest/4.jpg";
import ben_tau_con_dao_5 from "../../../../assets/images/ben-tau-con-dao-alcorest/5.jpg";
import ben_tau_con_dao_6 from "../../../../assets/images/ben-tau-con-dao-alcorest/6.jpg";
import ben_tau_con_dao_7 from "../../../../assets/images/ben-tau-con-dao-alcorest/7.jpg";
import ben_tau_con_dao_8 from "../../../../assets/images/ben-tau-con-dao-alcorest/8.jpg";
import ben_tau_con_dao_9 from "../../../../assets/images/ben-tau-con-dao-alcorest/9.jpg";
import ben_tau_con_dao_10 from "../../../../assets/images/ben-tau-con-dao-alcorest/10.jpg";

import dai_hoc_van_hien_1 from "../../../../assets/images/dai-hoc-van-hien-alcorest/1.jpg";
import dai_hoc_van_hien_2 from "../../../../assets/images/dai-hoc-van-hien-alcorest/2.jpg";
import dai_hoc_van_hien_3 from "../../../../assets/images/dai-hoc-van-hien-alcorest/3.jpg";
import dai_hoc_van_hien_4 from "../../../../assets/images/dai-hoc-van-hien-alcorest/4.jpg";
import dai_hoc_van_hien_5 from "../../../../assets/images/dai-hoc-van-hien-alcorest/5.jpg";
import dai_hoc_van_hien_6 from "../../../../assets/images/dai-hoc-van-hien-alcorest/6.jpg";
import dai_hoc_van_hien_7 from "../../../../assets/images/dai-hoc-van-hien-alcorest/7.jpg";
import dai_hoc_van_hien_8 from "../../../../assets/images/dai-hoc-van-hien-alcorest/8.jpg";
import dai_hoc_van_hien_9 from "../../../../assets/images/dai-hoc-van-hien-alcorest/9.jpg";
import dai_hoc_van_hien_10 from "../../../../assets/images/dai-hoc-van-hien-alcorest/10.jpg";
import dai_hoc_van_hien_11 from "../../../../assets/images/dai-hoc-van-hien-alcorest/11.jpg";
import dai_hoc_van_hien_12 from "../../../../assets/images/dai-hoc-van-hien-alcorest/12.jpg";
import dai_hoc_van_hien_13 from "../../../../assets/images/dai-hoc-van-hien-alcorest/13.jpg";

import lotte_hotel_1 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/1.jpeg";
import lotte_hotel_2 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/2.jpeg";
import lotte_hotel_3 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/3.jpeg";
import lotte_hotel_4 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/4.jpeg";
import lotte_hotel_5 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/5.jpeg";
import lotte_hotel_6 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/6.jpeg";
import lotte_hotel_7 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/7.jpeg";
import lotte_hotel_8 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/8.jpeg";
import lotte_hotel_9 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/9.jpeg";
import lotte_hotel_10 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/10.jpeg";
import lotte_hotel_11 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/11.jpeg";
import lotte_hotel_12 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/12.jpeg";
import lotte_hotel_13 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/13.jpeg";
import lotte_hotel_14 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/14.jpeg";
import lotte_hotel_15 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/15.jpeg";
import lotte_hotel_16 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/16.jpeg";
import lotte_hotel_17 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/17.jpeg";
import lotte_hotel_18 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/18.jpeg";
import lotte_hotel_19 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/19.jpeg";
import lotte_hotel_20 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/20.jpeg";
import lotte_hotel_21 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/21.jpeg";
import lotte_hotel_22 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/22.jpeg";
import lotte_hotel_23 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/23.jpeg";
import lotte_hotel_24 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/24.jpeg";
import lotte_hotel_25 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/25.jpeg";
import lotte_hotel_26 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/26.jpeg";
import lotte_hotel_27 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/27.jpeg";
import lotte_hotel_28 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/28.jpeg";
import lotte_hotel_29 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/29.jpeg";
import lotte_hotel_30 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/30.jpeg";
import lotte_hotel_31 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/31.jpeg";
import lotte_hotel_32 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/32.jpeg";
import lotte_hotel_33 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/33.jpeg";
import lotte_hotel_34 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/34.jpeg";
import lotte_hotel_35 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/35.jpeg";
import lotte_hotel_36 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/36.jpeg";
import lotte_hotel_37 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/37.jpeg";
import lotte_hotel_38 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/38.jpeg";
import lotte_hotel_39 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/39.jpeg";
import lotte_hotel_40 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/40.jpeg";
import lotte_hotel_41 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/41.jpeg";
import lotte_hotel_42 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/42.jpeg";
import lotte_hotel_43 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/43.jpeg";
import lotte_hotel_44 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/44.jpeg";
import lotte_hotel_45 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/45.jpeg";
import lotte_hotel_46 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/46.jpeg";
import lotte_hotel_47 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/47.jpeg";
import lotte_hotel_48 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/48.jpeg";
import lotte_hotel_49 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/49.jpeg";
import lotte_hotel_50 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/50.jpeg";
import lotte_hotel_51 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/51.jpeg";
import lotte_hotel_52 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/52.jpeg";
import lotte_hotel_53 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/53.jpeg";
import lotte_hotel_54 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/54.jpeg";
import lotte_hotel_55 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/55.jpeg";
import lotte_hotel_56 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/56.jpeg";
import lotte_hotel_57 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/57.jpeg";
import lotte_hotel_58 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/58.jpeg";
import lotte_hotel_59 from "../../../../assets/images/lotte-hotel-saigon-dpa-group/59.jpeg";

import toanha_viettel_complex_tower_1 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/1.jpeg";
import toanha_viettel_complex_tower_2 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/2.jpeg";
import toanha_viettel_complex_tower_3 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/3.jpeg";
import toanha_viettel_complex_tower_4 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/4.jpeg";
import toanha_viettel_complex_tower_5 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/5.jpeg";
import toanha_viettel_complex_tower_6 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/6.jpeg";
import toanha_viettel_complex_tower_7 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/7.jpeg";
import toanha_viettel_complex_tower_8 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/8.jpeg";
import toanha_viettel_complex_tower_9 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/9.jpeg";
import toanha_viettel_complex_tower_10 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/10.jpeg";
import toanha_viettel_complex_tower_11 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/11.jpeg";
import toanha_viettel_complex_tower_12 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/12.jpeg";
import toanha_viettel_complex_tower_13 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/13.jpeg";
import toanha_viettel_complex_tower_14 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/14.jpeg";
import toanha_viettel_complex_tower_15 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/15.jpeg";
import toanha_viettel_complex_tower_16 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/16.jpeg";
import toanha_viettel_complex_tower_17 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/17.jpeg";
import toanha_viettel_complex_tower_18 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/18.jpeg";
import toanha_viettel_complex_tower_19 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/19.jpeg";
import toanha_viettel_complex_tower_20 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/20.jpeg";
import toanha_viettel_complex_tower_21 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/21.jpeg";
import toanha_viettel_complex_tower_22 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/22.jpeg";
import toanha_viettel_complex_tower_23 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/23.jpeg";
import toanha_viettel_complex_tower_24 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/24.jpeg";
import toanha_viettel_complex_tower_25 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/25.jpeg";
import toanha_viettel_complex_tower_26 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/26.jpeg";
import toanha_viettel_complex_tower_27 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/27.jpeg";
import toanha_viettel_complex_tower_28 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/28.jpeg";
import toanha_viettel_complex_tower_29 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/29.jpeg";
import toanha_viettel_complex_tower_30 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/30.jpeg";
import toanha_viettel_complex_tower_31 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/31.jpeg";
import toanha_viettel_complex_tower_32 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/32.jpeg";
import toanha_viettel_complex_tower_33 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/33.jpeg";
import toanha_viettel_complex_tower_34 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/34.jpeg";
import toanha_viettel_complex_tower_35 from "../../../../assets/images/toanha-viettel-complex-tower-viettel/35.jpeg";

import vinakin_building_1 from "../../../../assets/images/vinakin/1.jpg";
import vinakin_building_2 from "../../../../assets/images/vinakin/2.jpg";
import vinakin_building_3 from "../../../../assets/images/vinakin/3.jpg";
import vinakin_building_4 from "../../../../assets/images/vinakin/4.jpg";
import vinakin_building_5 from "../../../../assets/images/vinakin/5.jpg";
import vinakin_building_6 from "../../../../assets/images/vinakin/6.jpg";
import vinakin_building_7 from "../../../../assets/images/vinakin/7.jpg";
import vinakin_building_8 from "../../../../assets/images/vinakin/8.jpg";
import vinakin_building_9 from "../../../../assets/images/vinakin/9.jpg";
import vinakin_building_10 from "../../../../assets/images/vinakin/10.jpg";
import vinakin_building_11 from "../../../../assets/images/vinakin/11.jpg";
import vinakin_building_12 from "../../../../assets/images/vinakin/12.jpg";
import vinakin_building_13 from "../../../../assets/images/vinakin/13.jpg";
import vinakin_building_14 from "../../../../assets/images/vinakin/14.jpg";
import vinakin_building_15 from "../../../../assets/images/vinakin/15.jpg";
import vinakin_building_16 from "../../../../assets/images/vinakin/16.jpg";
import vinakin_building_17 from "../../../../assets/images/vinakin/17.jpg";
import vinakin_building_18 from "../../../../assets/images/vinakin/18.jpg";
import vinakin_building_19 from "../../../../assets/images/vinakin/19.jpg";
import vinakin_building_20 from "../../../../assets/images/vinakin/20.jpg";
import vinakin_building_21 from "../../../../assets/images/vinakin/21.jpg";
import vinakin_building_22 from "../../../../assets/images/vinakin/22.jpg";
import vinakin_building_23 from "../../../../assets/images/vinakin/23.jpg";
import vinakin_building_24 from "../../../../assets/images/vinakin/24.jpg";
import vinakin_building_25 from "../../../../assets/images/vinakin/25.jpg";
import vinakin_building_26 from "../../../../assets/images/vinakin/26.jpg";
import vinakin_building_27 from "../../../../assets/images/vinakin/27.jpg";
import vinakin_building_28 from "../../../../assets/images/vinakin/28.jpg";
import vinakin_building_29 from "../../../../assets/images/vinakin/29.jpg";
import vinakin_building_30 from "../../../../assets/images/vinakin/30.jpg";
import vinakin_building_31 from "../../../../assets/images/vinakin/31.jpg";
import vinakin_building_32 from "../../../../assets/images/vinakin/32.jpg";
import vinakin_building_33 from "../../../../assets/images/vinakin/33.jpg";
import vinakin_building_34 from "../../../../assets/images/vinakin/34.jpg";
import vinakin_building_35 from "../../../../assets/images/vinakin/35.jpg";
import vinakin_building_36 from "../../../../assets/images/vinakin/36.jpg";
import vinakin_building_37 from "../../../../assets/images/vinakin/37.jpg";
import vinakin_building_38 from "../../../../assets/images/vinakin/38.jpg";
import vinakin_building_39 from "../../../../assets/images/vinakin/39.jpg";
import vinakin_building_40 from "../../../../assets/images/vinakin/40.jpg";
import vinakin_building_41 from "../../../../assets/images/vinakin/41.jpg";

import mam_non_riverside_residence_q7_1 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/1.JPG";
import mam_non_riverside_residence_q7_2 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/2.JPG";
import mam_non_riverside_residence_q7_3 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/3.JPG";
import mam_non_riverside_residence_q7_4 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/4.JPG";
import mam_non_riverside_residence_q7_5 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/5.JPG";
import mam_non_riverside_residence_q7_6 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/6.JPG";
import mam_non_riverside_residence_q7_7 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/7.JPG";
import mam_non_riverside_residence_q7_8 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/8.JPG";
import mam_non_riverside_residence_q7_9 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/9.JPG";
import mam_non_riverside_residence_q7_10 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/10.JPG";
import mam_non_riverside_residence_q7_11 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/11.JPG";
import mam_non_riverside_residence_q7_12 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/12.JPG";
import mam_non_riverside_residence_q7_13 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/13.JPG";
import mam_non_riverside_residence_q7_14 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/14.JPG";
import mam_non_riverside_residence_q7_15 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/15.JPG";
import mam_non_riverside_residence_q7_16 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/16.JPG";
import mam_non_riverside_residence_q7_17 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/17.JPG";
import mam_non_riverside_residence_q7_18 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/18.JPG";
import mam_non_riverside_residence_q7_19 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/19.JPG";
import mam_non_riverside_residence_q7_20 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/20.JPG";
import mam_non_riverside_residence_q7_21 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/21.JPG";
import mam_non_riverside_residence_q7_22 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/22.JPG";
import mam_non_riverside_residence_q7_23 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/23.JPG";
import mam_non_riverside_residence_q7_24 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/24.JPG";
import mam_non_riverside_residence_q7_25 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/25.JPG";
import mam_non_riverside_residence_q7_26 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/26.JPG";
import mam_non_riverside_residence_q7_27 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/27.JPG";
import mam_non_riverside_residence_q7_28 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/28.JPG";
import mam_non_riverside_residence_q7_29 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/29.JPG";
import mam_non_riverside_residence_q7_30 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/30.JPG";
import mam_non_riverside_residence_q7_31 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/31.JPG";
import mam_non_riverside_residence_q7_32 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/32.JPG";
import mam_non_riverside_residence_q7_33 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/33.JPG";
import mam_non_riverside_residence_q7_34 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/34.JPG";
import mam_non_riverside_residence_q7_35 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/35.JPG";
import mam_non_riverside_residence_q7_36 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/36.JPG";
import mam_non_riverside_residence_q7_37 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/37.JPG";
import mam_non_riverside_residence_q7_38 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/38.JPG";
import mam_non_riverside_residence_q7_39 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/39.JPG";
import mam_non_riverside_residence_q7_40 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/40.JPG";
import mam_non_riverside_residence_q7_41 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/41.JPG";
import mam_non_riverside_residence_q7_42 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/42.JPG";
import mam_non_riverside_residence_q7_43 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/43.JPG";
import mam_non_riverside_residence_q7_44 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/44.JPG";
import mam_non_riverside_residence_q7_45 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/45.JPG";
import mam_non_riverside_residence_q7_46 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/46.JPG";
import mam_non_riverside_residence_q7_47 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/47.JPG";
import mam_non_riverside_residence_q7_48 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/48.JPG";
import mam_non_riverside_residence_q7_49 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/49.JPG";
import mam_non_riverside_residence_q7_50 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/50.JPG";
import mam_non_riverside_residence_q7_51 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/51.JPG";
import mam_non_riverside_residence_q7_52 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/52.JPG";
import mam_non_riverside_residence_q7_53 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/53.JPG";
import mam_non_riverside_residence_q7_54 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/54.JPG";
import mam_non_riverside_residence_q7_55 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/55.JPG";
import mam_non_riverside_residence_q7_56 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/56.JPG";
import mam_non_riverside_residence_q7_57 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/57.JPG";
import mam_non_riverside_residence_q7_58 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/58.JPG";
import mam_non_riverside_residence_q7_59 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/59.JPG";
import mam_non_riverside_residence_q7_60 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/60.JPG";
import mam_non_riverside_residence_q7_61 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/61.JPG";
import mam_non_riverside_residence_q7_62 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/62.JPG";
import mam_non_riverside_residence_q7_63 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/63.JPG";
import mam_non_riverside_residence_q7_64 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/64.JPG";
import mam_non_riverside_residence_q7_65 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/65.JPG";
import mam_non_riverside_residence_q7_66 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/66.JPG";
import mam_non_riverside_residence_q7_67 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/67.JPG";
import mam_non_riverside_residence_q7_68 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/68.JPG";
import mam_non_riverside_residence_q7_69 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/69.JPG";
import mam_non_riverside_residence_q7_70 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/70.JPG";
import mam_non_riverside_residence_q7_71 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/71.JPG";
import mam_non_riverside_residence_q7_72 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/72.JPG";
import mam_non_riverside_residence_q7_73 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/73.JPG";
import mam_non_riverside_residence_q7_74 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/74.JPG";
import mam_non_riverside_residence_q7_75 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/75.JPG";
import mam_non_riverside_residence_q7_76 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/76.JPG";
import mam_non_riverside_residence_q7_77 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/77.JPG";
import mam_non_riverside_residence_q7_78 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/78.JPG";
import mam_non_riverside_residence_q7_79 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/79.JPG";
import mam_non_riverside_residence_q7_80 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/80.JPG";
import mam_non_riverside_residence_q7_81 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/81.JPG";
import mam_non_riverside_residence_q7_82 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/82.JPG";
import mam_non_riverside_residence_q7_83 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/83.JPG";
import mam_non_riverside_residence_q7_84 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/84.JPG";
import mam_non_riverside_residence_q7_85 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/85.JPG";
import mam_non_riverside_residence_q7_86 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/86.JPG";
import mam_non_riverside_residence_q7_87 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/87.JPG";
import mam_non_riverside_residence_q7_88 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/88.JPG";
import mam_non_riverside_residence_q7_89 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/89.JPG";
import mam_non_riverside_residence_q7_90 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/80.JPG";
import mam_non_riverside_residence_q7_91 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/91.JPG";
import mam_non_riverside_residence_q7_92 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/92.JPG";
import mam_non_riverside_residence_q7_93 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/93.JPG";
import mam_non_riverside_residence_q7_94 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/94.JPG";
import mam_non_riverside_residence_q7_95 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/95.JPG";
import mam_non_riverside_residence_q7_96 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/96.JPG";
import mam_non_riverside_residence_q7_97 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/97.JPG";
import mam_non_riverside_residence_q7_98 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/98.JPG";
import mam_non_riverside_residence_q7_99 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/99.JPG";
import mam_non_riverside_residence_q7_100 from "../../../../assets/images/mam-non-riverside-residence-q7-thien-pho/100.JPG";

export const filter = [
  {
    id: 1,
    text: (
      <FormattedMessage
        id="services.photograph.body.filter.all"
        defaultMessage="Tất Cả"
      />
    ),
    hashtag: null,
  },
  {
    id: 2,
    text: (
      <FormattedMessage
        id="footer.photograph.realestate"
        defaultMessage="BĐS"
      />
    ),
    hashtag: "realestate",
  },
  {
    id: 3,
    text: (
      <FormattedMessage
        id="footer.photograph.inducstrialarea"
        defaultMessage="KCN"
      />
    ),
    hashtag: "inducstrialarea",
  },
  {
    id: 4,
    text: (
      <FormattedMessage
        id="footer.photograph.factory"
        defaultMessage="Nhà Máy"
      />
    ),
    hashtag: "factory",
  },
  // {
  //   id: 5,
  //   text: (
  //     <FormattedMessage
  //       id="footer.photograph.weddingrestaurant"
  //       defaultMessage="Nhà Hàng, Tiệc Cưới"
  //     />
  //   ),
  //   hashtag: "weddingrestaurant",
  // },
  // {
  //   id: 6,
  //   text: (
  //     <FormattedMessage
  //       id="footer.photograph.showroom"
  //       defaultMessage="Show Room"
  //     />
  //   ),
  //   hashtag: "showroom",
  // },
  // {
  //   id: 7,
  //   text: (
  //     <FormattedMessage
  //       id="footer.photograph.office"
  //       defaultMessage="Văn Phòng"
  //     />
  //   ),
  //   hashtag: "office",
  // },
  // {
  //   id: 8,
  //   text: (
  //     <FormattedMessage id="footer.photograph.house" defaultMessage="Nhà Ở" />
  //   ),
  //   hashtag: "house",
  // },
  // {
  //   id: 9,
  //   text: (
  //     <FormattedMessage
  //       id="footer.photograph.businessspace"
  //       defaultMessage="Không Gian Kinh Doanh"
  //     />
  //   ),
  //   hashtag: "businessspace",
  // },
  // {
  //   id: 10,
  //   text: (
  //     <FormattedMessage
  //       id="footer.photograph.productspace"
  //       defaultMessage="Không Gian Sản Phẩm"
  //     />
  //   ),
  //   hashtag: "productspace",
  // },
  {
    id: 11,
    text: (
      <FormattedMessage
        id="footer.photograph.architecture"
        defaultMessage="Kiến Trúc"
      />
    ),
    hashtag: "architecture",
  },
  {
    id: 12,
    text: (
      <FormattedMessage
        id="footer.photograph.school"
        defaultMessage="Trường Học"
      />
    ),
    hashtag: "school",
  },
  {
    id: 13,
    text: (
      <FormattedMessage
        id="footer.photograph.hotel"
        defaultMessage="Khách Sạn"
      />
    ),
    hashtag: "hotel",
  },
  {
    id: 14,
    text: <FormattedMessage id="footer.photograph.cgi" defaultMessage="CGI" />,
    hashtag: "cgi",
  },
  {
    id: 15,
    text: <FormattedMessage id="footer.photograph.360" defaultMessage="360" />,
    hashtag: "360",
  },
];

export const ServicesPhotographBodyList = [
  {
    id: 1,
    title: "The Sóng",
    text: "An Gia",
    thumb: the_song_1,
    projectname: "the-song",
    location: "Vũng Tàu",
    client: "An Gia",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: ["architecture", "realestate"],
    img: [
      the_song_1,
      the_song_2,
      the_song_3,
      the_song_4,
      the_song_5,
      the_song_6,
      the_song_7,
      the_song_8,
      the_song_9,
      the_song_10,
      the_song_11,
      the_song_12,
      the_song_13,
      the_song_14,
      the_song_15,
      the_song_16,
    ],
  },
  {
    id: 2,
    title: "Thảo Điền Green",
    text: "BM Windows",
    projectname: "thao-dien-green",
    location: "TP.HCM",
    client: "BM Windows",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: ["architecture", "realestate"],
    thumb: thao_dien_green_1,
    img: [
      thao_dien_green_1,
      thao_dien_green_2,
      thao_dien_green_3,
      thao_dien_green_4,
      thao_dien_green_5,
      thao_dien_green_6,
      thao_dien_green_7,
      thao_dien_green_8,
      thao_dien_green_9,
      thao_dien_green_10,
      thao_dien_green_11,
      thao_dien_green_12,
      thao_dien_green_13,
    ],
  },
  {
    id: 3,
    title: "Charm Ruby",
    text: "BM Windows",
    projectname: "charm-ruby",
    location: "Bình Dương",
    client: "BM Windows",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: ["architecture", "realestate"],
    thumb: charm_ruby_4,
    img: [
      charm_ruby_1,
      charm_ruby_2,
      charm_ruby_3,
      charm_ruby_4,
      charm_ruby_5,
      charm_ruby_6,
      charm_ruby_7,
      charm_ruby_8,
      charm_ruby_9,
      charm_ruby_10,
      charm_ruby_11,
      charm_ruby_12,
    ],
  },
  {
    id: 4,
    title: "The Classia",
    text: "Khang Điền",
    projectname: "the-classia",
    location: "TP.HCM",
    client: "Khang Điền",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: ["architecture", "realestate"],
    thumb: the_classia_9,
    img: [
      the_classia_1,
      the_classia_2,
      the_classia_3,
      the_classia_4,
      the_classia_5,
      the_classia_6,
      the_classia_7,
      the_classia_8,
      the_classia_9,
      the_classia_10,
      the_classia_11,
      the_classia_12,
      the_classia_13,
      the_classia_14,
      the_classia_15,
      the_classia_16,
      the_classia_17,
    ],
  },
  {
    id: 5,
    title: "Eaton Park",
    text: "Gamuda Land",
    projectname: "eaton-park",
    location: "TP.HCM",
    client: "Gamuda Land",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: ["cgi"],
    thumb: mct_4,
    img: [mct_1, mct_2, mct_3, mct_4, mct_5, mct_6, mct_7, mct_8, mct_9, mct_10, mct_11, mct_12, mct_13],
  },
  {
    id: 6,
    title: "Artisan Park",
    text: "Gamuda Land",
    projectname: "artisan-park",
    location: "Bình Dương",
    client: "Gamuda Land",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: [
      "architecture",
      "realestate",
      // "house",
      // "showroom",
      // "productspace",
    ],
    thumb: artisan_park_4,
    img: [
      artisan_park_1,
      artisan_park_2,
      artisan_park_3,
      artisan_park_4,
      artisan_park_5,
      artisan_park_6,
      artisan_park_7,
      artisan_park_8,
      artisan_park_9,
      artisan_park_10,
      artisan_park_11,
      artisan_park_12,
      artisan_park_13,
      artisan_park_14,
      artisan_park_15,
      artisan_park_16,
      artisan_park_17,
      artisan_park_18,
      artisan_park_19,
      artisan_park_20,
      artisan_park_21,
      artisan_park_22,
      artisan_park_23,
      artisan_park_24,
      artisan_park_25,
      artisan_park_26,
      artisan_park_27,
      artisan_park_28,
      artisan_park_29,
      artisan_park_30,
      artisan_park_31,
      artisan_park_32,
      artisan_park_33,
      artisan_park_34,
      artisan_park_35,
      artisan_park_36,
      artisan_park_37,
      artisan_park_38,
      artisan_park_39,
      artisan_park_40,
      artisan_park_41,
      artisan_park_42,
      artisan_park_43,
      artisan_park_44,
      artisan_park_45,
      artisan_park_46,
      artisan_park_47,
    ],
  },
  {
    id: 7,
    title: "Fiato Premier",
    text: "Thăng Long Real",
    projectname: "fiato-permier",
    location: "TP.HCM",
    client: "Thăng Long Real",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: [
      "architecture", 
      "realestate", 
      // "house", 
      // "showroom"
    ],
    thumb: fiato_premier_1,
    img: [
      fiato_premier_1,
      fiato_premier_2,
      fiato_premier_3,
      fiato_premier_4,
      fiato_premier_5,
      fiato_premier_6,
      fiato_premier_7,
      fiato_premier_8,
      fiato_premier_9,
      fiato_premier_10,
      fiato_premier_11,
      fiato_premier_12,
      fiato_premier_13,
      fiato_premier_14,
      fiato_premier_15,
      fiato_premier_16,
      fiato_premier_17,
      fiato_premier_18,
      fiato_premier_19,
      fiato_premier_20,
      fiato_premier_21,
      fiato_premier_22,
      fiato_premier_23,
      fiato_premier_24,
      fiato_premier_25,
      fiato_premier_26,
      fiato_premier_27,
      fiato_premier_28,
      fiato_premier_29,
      fiato_premier_30,
      fiato_premier_31,
      fiato_premier_32,
      fiato_premier_33,
      fiato_premier_34,
      fiato_premier_35,
      fiato_premier_36,
      fiato_premier_37,
      fiato_premier_38,
      fiato_premier_39,
      fiato_premier_40,
      fiato_premier_41,
      fiato_premier_42,
      fiato_premier_43,
    ],
  },
  {
    id: 8,
    title: "Vinhomes Central Park",
    text: "VinGroup",
    projectname: "vinhomes-central-park",
    location: "TP.HCM",
    client: "VinGroup",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: ["architecture", "realestate"],
    thumb: vinhomes_central_park_7,
    img: [
      vinhomes_central_park_1,
      vinhomes_central_park_2,
      vinhomes_central_park_3,
      vinhomes_central_park_4,
      vinhomes_central_park_5,
      vinhomes_central_park_6,
      vinhomes_central_park_7,
      vinhomes_central_park_8,
      vinhomes_central_park_9,
      vinhomes_central_park_10,
      vinhomes_central_park_11,
      vinhomes_central_park_12,
      vinhomes_central_park_13,
      vinhomes_central_park_14,
      vinhomes_central_park_15,
      vinhomes_central_park_16,
      vinhomes_central_park_17,
      vinhomes_central_park_18,
      vinhomes_central_park_19,
      vinhomes_central_park_20,
      vinhomes_central_park_21,
      vinhomes_central_park_22,
      vinhomes_central_park_23,
      vinhomes_central_park_24,
      vinhomes_central_park_25,
      vinhomes_central_park_26,
      vinhomes_central_park_27,
      vinhomes_central_park_28,
      vinhomes_central_park_29,
      vinhomes_central_park_30,
      vinhomes_central_park_31,
      vinhomes_central_park_32,
      vinhomes_central_park_33,
      vinhomes_central_park_34,
      vinhomes_central_park_35,
      vinhomes_central_park_36,
      vinhomes_central_park_37,
      vinhomes_central_park_38,
      vinhomes_central_park_39,
      vinhomes_central_park_40,
      vinhomes_central_park_41,
      vinhomes_central_park_42,
      vinhomes_central_park_43,
      vinhomes_central_park_44,
      vinhomes_central_park_45,
      vinhomes_central_park_46,
      vinhomes_central_park_47,
      vinhomes_central_park_48,
      vinhomes_central_park_49,
      vinhomes_central_park_50,
      vinhomes_central_park_51,
      vinhomes_central_park_52,
      vinhomes_central_park_53,
      vinhomes_central_park_54,
      vinhomes_central_park_55,
      vinhomes_central_park_56,
      vinhomes_central_park_57,
      vinhomes_central_park_58,
      vinhomes_central_park_59,
      vinhomes_central_park_60,
      vinhomes_central_park_61,
      vinhomes_central_park_62,
      vinhomes_central_park_63,
      vinhomes_central_park_64,
      vinhomes_central_park_65,
      vinhomes_central_park_66,
      vinhomes_central_park_67,
      vinhomes_central_park_68,
      vinhomes_central_park_69,
      vinhomes_central_park_70,
      vinhomes_central_park_71,
      vinhomes_central_park_72,
      vinhomes_central_park_73,
      vinhomes_central_park_74,
      vinhomes_central_park_75,
      vinhomes_central_park_76,
      vinhomes_central_park_77,
      vinhomes_central_park_78,
      vinhomes_central_park_79,
      vinhomes_central_park_80,
    ],
  },
  {
    id: 9,
    title: "Vinhomes Grand Park",
    text: "VinGroup",
    projectname: "vinhomes-grand-park",
    location: "TP.HCM",
    client: "VinGroup",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: ["architecture", "realestate"],
    thumb: vinhomes_grand_park_9,
    img: [
      vinhomes_grand_park_1,
      vinhomes_grand_park_2,
      vinhomes_grand_park_3,
      vinhomes_grand_park_4,
      vinhomes_grand_park_5,
      vinhomes_grand_park_6,
      vinhomes_grand_park_7,
      vinhomes_grand_park_8,
      vinhomes_grand_park_9,
      vinhomes_grand_park_10,
      vinhomes_grand_park_11,
      vinhomes_grand_park_12,
      vinhomes_grand_park_13,
      vinhomes_grand_park_14,
      vinhomes_grand_park_15,
      vinhomes_grand_park_16,
      vinhomes_grand_park_17,
      vinhomes_grand_park_18,
      vinhomes_grand_park_19,
      vinhomes_grand_park_20,
      vinhomes_grand_park_21,
      vinhomes_grand_park_22,
      vinhomes_grand_park_23,
      vinhomes_grand_park_24,
      vinhomes_grand_park_25,
      vinhomes_grand_park_26,
      vinhomes_grand_park_27,
      vinhomes_grand_park_28,
      vinhomes_grand_park_29,
      vinhomes_grand_park_30,
      vinhomes_grand_park_31,
      vinhomes_grand_park_32,
      vinhomes_grand_park_33,
      vinhomes_grand_park_34,
      vinhomes_grand_park_35,
      vinhomes_grand_park_36,
      vinhomes_grand_park_37,
    ],
  },
  {
    id: 10,
    title: "Grandworld Phú Quốc",
    text: "VinGroup",
    projectname: "grandworld-phu-quoc",
    location: "Phú Quốc",
    client: "VinGroup",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: ["architecture", "realestate"],
    thumb: grandworld_phu_quoc_22,
    img: [
      grandworld_phu_quoc_1,
      grandworld_phu_quoc_2,
      grandworld_phu_quoc_3,
      grandworld_phu_quoc_4,
      grandworld_phu_quoc_5,
      grandworld_phu_quoc_6,
      grandworld_phu_quoc_7,
      grandworld_phu_quoc_8,
      grandworld_phu_quoc_9,
      grandworld_phu_quoc_10,
      grandworld_phu_quoc_11,
      grandworld_phu_quoc_12,
      grandworld_phu_quoc_13,
      grandworld_phu_quoc_14,
      grandworld_phu_quoc_15,
      grandworld_phu_quoc_16,
      grandworld_phu_quoc_17,
      grandworld_phu_quoc_18,
      grandworld_phu_quoc_19,
      grandworld_phu_quoc_20,
      grandworld_phu_quoc_21,
      grandworld_phu_quoc_22,
      grandworld_phu_quoc_23,
      grandworld_phu_quoc_24,
      grandworld_phu_quoc_25,
      grandworld_phu_quoc_26,
      grandworld_phu_quoc_27,
      grandworld_phu_quoc_28,
      grandworld_phu_quoc_29,
      grandworld_phu_quoc_30,
      grandworld_phu_quoc_31,
      grandworld_phu_quoc_32,
      grandworld_phu_quoc_33,
      grandworld_phu_quoc_34,
      grandworld_phu_quoc_35,
      grandworld_phu_quoc_36,
      grandworld_phu_quoc_37,
      grandworld_phu_quoc_38,
      grandworld_phu_quoc_39,
      grandworld_phu_quoc_40,
    ],
  },
  {
    id: 11,
    title: "Đắk Srông 3A&3B",
    text: "Bitexco Power",
    projectname: "dak-srong-3a-3b",
    location: "Gia Lai",
    client: "Bitexco Power",
    executionTime: 2023,
    photo: "Giang Ho",
    hashtag: ["factory"],
    thumb: dak_srong_3a_3b_1,
    img: [
      dak_srong_3a_3b_1,
      dak_srong_3a_3b_2,
      dak_srong_3a_3b_3,
      dak_srong_3a_3b_4,
      dak_srong_3a_3b_5,
      dak_srong_3a_3b_6,
      dak_srong_3a_3b_7,
      dak_srong_3a_3b_8,
      dak_srong_3a_3b_9,
      dak_srong_3a_3b_10,
      dak_srong_3a_3b_11,
      dak_srong_3a_3b_12,
      dak_srong_3a_3b_13,
      dak_srong_3a_3b_14,
      dak_srong_3a_3b_15,
    ],
  },
  {
    id: 12,
    title: "Lộc Ninh 5",
    text: "Bitexco Power",
    projectname: "loc-ninh-5",
    location: "Bình Phước",
    client: "Bitexco Power",
    executionTime: 2023,
    photo: "Giang Ho",
    hashtag: ["factory"],
    thumb: loc_ninh_5_1,
    img: [
      loc_ninh_5_1,
      loc_ninh_5_2,
      loc_ninh_5_3,
      loc_ninh_5_4,
      loc_ninh_5_5,
      loc_ninh_5_6,
      loc_ninh_5_7,
      loc_ninh_5_8,
      loc_ninh_5_9,
      loc_ninh_5_10,
      loc_ninh_5_11,
      loc_ninh_5_12,
      loc_ninh_5_13,
      loc_ninh_5_14,
      loc_ninh_5_15,
      loc_ninh_5_16,
    ],
  },
  {
    id: 13,
    title: "Bệnh Viện Đa Khoa Tỉnh Tiền Giang",
    text: "BM Windows",
    projectname: "bvdk-tinh-tien-giang",
    location: "Tiền Giang",
    client: "BM Windows",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: ["architecture"],
    thumb: bvdk_tinh_tien_giang_18,
    img: [
      bvdk_tinh_tien_giang_1,
      bvdk_tinh_tien_giang_2,
      bvdk_tinh_tien_giang_3,
      bvdk_tinh_tien_giang_4,
      bvdk_tinh_tien_giang_5,
      bvdk_tinh_tien_giang_6,
      bvdk_tinh_tien_giang_7,
      bvdk_tinh_tien_giang_8,
      bvdk_tinh_tien_giang_9,
      bvdk_tinh_tien_giang_10,
      bvdk_tinh_tien_giang_11,
      bvdk_tinh_tien_giang_12,
      bvdk_tinh_tien_giang_13,
      bvdk_tinh_tien_giang_14,
      bvdk_tinh_tien_giang_15,
      bvdk_tinh_tien_giang_16,
      bvdk_tinh_tien_giang_17,
      bvdk_tinh_tien_giang_18,
    ],
  },
  {
    id: 14,
    title: "Nhà Máy BM Windows",
    text: "BM Windows",
    projectname: "nha-may-bm-windows",
    location: "Bình Dương",
    client: "BM Windows",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: ["factory"],
    thumb: nha_may_bm_windows_16,
    img: [
      nha_may_bm_windows_1,
      nha_may_bm_windows_2,
      nha_may_bm_windows_3,
      nha_may_bm_windows_4,
      nha_may_bm_windows_5,
      nha_may_bm_windows_6,
      nha_may_bm_windows_7,
      nha_may_bm_windows_8,
      nha_may_bm_windows_9,
      nha_may_bm_windows_10,
      nha_may_bm_windows_11,
      nha_may_bm_windows_12,
      nha_may_bm_windows_13,
      nha_may_bm_windows_14,
      nha_may_bm_windows_15,
      nha_may_bm_windows_16,
      nha_may_bm_windows_17,
      nha_may_bm_windows_18,
      nha_may_bm_windows_19,
      nha_may_bm_windows_20,
      nha_may_bm_windows_21,
      nha_may_bm_windows_22,
      nha_may_bm_windows_23,
      nha_may_bm_windows_24,
      nha_may_bm_windows_25,
      nha_may_bm_windows_26,
      nha_may_bm_windows_27,
      nha_may_bm_windows_28,
      nha_may_bm_windows_29,
      nha_may_bm_windows_30,
    ],
  },
  {
    id: 15,
    title: "Techcombank Lê Duẩn",
    text: "BM Windows",
    projectname: "techcombank-le-duan",
    location: "TP.HCM",
    client: "BM Windows",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: ["architecture"],
    thumb: techcombank_leduan_2,
    img: [
      techcombank_leduan_1,
      techcombank_leduan_2,
      techcombank_leduan_3,
      techcombank_leduan_4,
      techcombank_leduan_5,
      techcombank_leduan_6,
      techcombank_leduan_7,
      techcombank_leduan_8,
      techcombank_leduan_9,
      techcombank_leduan_10,
      techcombank_leduan_11,
      techcombank_leduan_12,
      techcombank_leduan_13,
      techcombank_leduan_14,
      techcombank_leduan_15,
    ],
  },
  {
    id: 16,
    title: "Nhà Máy Điện Gió Nhơn Hội",
    text: "Xuân Cầu Group",
    projectname: "nmdg-nhon-hoi",
    location: "Quy Nhơn, Bình Định",
    client: "Xuân Cầu Group",
    executionTime: 2023,
    photo: "Giang Ho",
    hashtag: ["factory"],
    thumb: nmdg_nhon_hoi_2,
    img: [
      nmdg_nhon_hoi_1,
      nmdg_nhon_hoi_2,
      nmdg_nhon_hoi_3,
      nmdg_nhon_hoi_4,
      nmdg_nhon_hoi_5,
      nmdg_nhon_hoi_6,
      nmdg_nhon_hoi_7,
      nmdg_nhon_hoi_8,
      nmdg_nhon_hoi_9,
      nmdg_nhon_hoi_10,
      nmdg_nhon_hoi_11,
      nmdg_nhon_hoi_12,
      nmdg_nhon_hoi_13,
      nmdg_nhon_hoi_14,
    ],
  },
  {
    id: 17,
    title: "Nhà Máy Điện Gió Sóc Trăng 7",
    text: "Xuân Cầu Group",
    projectname: "nmdg-st7",
    location: "Sóc Trăng",
    client: "Xuân Cầu Group",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: ["factory"],
    thumb: nmdg_st7_20,
    img: [
      nmdg_st7_1,
      nmdg_st7_2,
      nmdg_st7_3,
      nmdg_st7_4,
      nmdg_st7_5,
      nmdg_st7_6,
      nmdg_st7_7,
      nmdg_st7_8,
      nmdg_st7_9,
      nmdg_st7_10,
      nmdg_st7_11,
      nmdg_st7_12,
      nmdg_st7_13,
      nmdg_st7_14,
      nmdg_st7_15,
      nmdg_st7_16,
      nmdg_st7_17,
      nmdg_st7_18,
      nmdg_st7_19,
      nmdg_st7_20,
      nmdg_st7_21,
      nmdg_st7_22,
    ],
  },
  // {
  //   id: 18,
  //   title: "Showroom Lạc Gia",
  //   text: "Nội Thất Lạc Gia",
  //   projectname: "showroom-lac-gia",
  //   location: "TP.HCM",
  //   client: "Nội Thất Lạc Gia",
  //   executionTime: 2024,
  //   photo: "Giang Ho",
  //   hashtag: ["showroom", "house", "productspace"],
  //   thumb: showroom_lac_gia_5,
  //   img: [
  //     showroom_lac_gia_1,
  //     showroom_lac_gia_2,
  //     showroom_lac_gia_3,
  //     showroom_lac_gia_4,
  //     showroom_lac_gia_5,
  //     showroom_lac_gia_6,
  //     showroom_lac_gia_7,
  //     showroom_lac_gia_8,
  //     showroom_lac_gia_9,
  //     showroom_lac_gia_10,
  //     showroom_lac_gia_11,
  //     showroom_lac_gia_12,
  //     showroom_lac_gia_13,
  //     showroom_lac_gia_14,
  //     showroom_lac_gia_15,
  //     showroom_lac_gia_16,
  //     showroom_lac_gia_17,
  //     showroom_lac_gia_18,
  //     showroom_lac_gia_19,
  //     showroom_lac_gia_20,
  //     showroom_lac_gia_21,
  //     showroom_lac_gia_22,
  //     showroom_lac_gia_23,
  //     showroom_lac_gia_24,
  //     showroom_lac_gia_25,
  //     showroom_lac_gia_26,
  //     showroom_lac_gia_27,
  //     showroom_lac_gia_28,
  //     showroom_lac_gia_29,
  //     showroom_lac_gia_30,
  //     showroom_lac_gia_31,
  //     showroom_lac_gia_32,
  //     showroom_lac_gia_33,
  //     showroom_lac_gia_34,
  //     showroom_lac_gia_35,
  //     showroom_lac_gia_36,
  //     showroom_lac_gia_37,
  //     showroom_lac_gia_38,
  //     showroom_lac_gia_39,
  //     showroom_lac_gia_40,
  //     showroom_lac_gia_41,
  //     showroom_lac_gia_42,
  //   ],
  // },
  {
    id: 19,
    title: "Bến Tàu Côn Đảo",
    text: "Alcorest",
    projectname: "ben-tau-con-dao",
    location: "Vũng Tàu",
    client: "Alcorest",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: ["architecture"],
    thumb: ben_tau_con_dao_2,
    img: [
      ben_tau_con_dao_1,
      ben_tau_con_dao_2,
      ben_tau_con_dao_3,
      ben_tau_con_dao_4,
      ben_tau_con_dao_5,
      ben_tau_con_dao_6,
      ben_tau_con_dao_7,
      ben_tau_con_dao_8,
      ben_tau_con_dao_9,
      ben_tau_con_dao_10,
    ],
  },
  {
    id: 20,
    title: "Đại Học Văn Hiến",
    text: "Alcorest",
    projectname: "dai-hoc-van-hien",
    location: "TP.HCM",
    client: "Alcorest",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: ["school", "architecture"],
    thumb: dai_hoc_van_hien_6,
    img: [
      dai_hoc_van_hien_1,
      dai_hoc_van_hien_2,
      dai_hoc_van_hien_3,
      dai_hoc_van_hien_4,
      dai_hoc_van_hien_5,
      dai_hoc_van_hien_6,
      dai_hoc_van_hien_7,
      dai_hoc_van_hien_8,
      dai_hoc_van_hien_9,
      dai_hoc_van_hien_10,
      dai_hoc_van_hien_11,
      dai_hoc_van_hien_12,
      dai_hoc_van_hien_13,
    ],
  },
  // {
  //   id: 21,
  //   title: "Thẩm Mỹ Viện Gangnam",
  //   text: "Mega Gangnam",
  //   projectname: "tmv-gangnam",
  //   location: "TP.HCM",
  //   client: "Mega Gangnam",
  //   executionTime: 2024,
  //   photo: "Giang Ho",
  //   hashtag: ["businessspace"],
  //   thumb: tmv_gangnam_5,
  //   img: [
  //     tmv_gangnam_1,
  //     tmv_gangnam_2,
  //     tmv_gangnam_3,
  //     tmv_gangnam_4,
  //     tmv_gangnam_5,
  //     tmv_gangnam_6,
  //     tmv_gangnam_7,
  //     tmv_gangnam_8,
  //     tmv_gangnam_9,
  //     tmv_gangnam_10,
  //     tmv_gangnam_11,
  //     tmv_gangnam_12,
  //     tmv_gangnam_13,
  //     tmv_gangnam_14,
  //     tmv_gangnam_15,
  //     tmv_gangnam_16,
  //     tmv_gangnam_17,
  //     tmv_gangnam_18,
  //     tmv_gangnam_19,
  //     tmv_gangnam_20,
  //     tmv_gangnam_21,
  //     tmv_gangnam_22,
  //   ],
  // },
  {
    id: 22,
    title: "Lotte Hotel Sài Gòn",
    text: "DPA Group",
    projectname: "lotte-hotel",
    location: "TP.HCM",
    client: "DPA Group",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: ["hotel", "architecture"],
    thumb: lotte_hotel_2,
    img: [
      lotte_hotel_1,
      lotte_hotel_2,
      lotte_hotel_3,
      lotte_hotel_4,
      lotte_hotel_5,
      lotte_hotel_6,
      lotte_hotel_7,
      lotte_hotel_8,
      lotte_hotel_9,
      lotte_hotel_10,
      lotte_hotel_11,
      lotte_hotel_12,
      lotte_hotel_13,
      lotte_hotel_14,
      lotte_hotel_15,
      lotte_hotel_16,
      lotte_hotel_17,
      lotte_hotel_18,
      lotte_hotel_19,
      lotte_hotel_20,
      lotte_hotel_21,
      lotte_hotel_22,
      lotte_hotel_23,
      lotte_hotel_24,
      lotte_hotel_25,
      lotte_hotel_26,
      lotte_hotel_27,
      lotte_hotel_28,
      lotte_hotel_29,
      lotte_hotel_30,
      lotte_hotel_31,
      lotte_hotel_32,
      lotte_hotel_33,
      lotte_hotel_34,
      lotte_hotel_35,
      lotte_hotel_36,
      lotte_hotel_37,
      lotte_hotel_38,
      lotte_hotel_39,
      lotte_hotel_40,
      lotte_hotel_41,
      lotte_hotel_42,
      lotte_hotel_43,
      lotte_hotel_44,
      lotte_hotel_45,
      lotte_hotel_46,
      lotte_hotel_47,
      lotte_hotel_48,
      lotte_hotel_49,
      lotte_hotel_50,
      lotte_hotel_51,
      lotte_hotel_52,
      lotte_hotel_53,
      lotte_hotel_54,
      lotte_hotel_55,
      lotte_hotel_56,
      lotte_hotel_57,
      lotte_hotel_58,
      lotte_hotel_59,
    ],
  },
  {
    id: 23,
    title: "Toà Nhà Viettel Complex Tower",
    text: "Viettel",
    projectname: "toa-nha-viettel-complex-tower",
    location: "TP.HCM",
    client: "Viettel",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: ["architecture"],
    thumb: toanha_viettel_complex_tower_7,
    img: [
      toanha_viettel_complex_tower_1,
      toanha_viettel_complex_tower_2,
      toanha_viettel_complex_tower_3,
      toanha_viettel_complex_tower_4,
      toanha_viettel_complex_tower_5,
      toanha_viettel_complex_tower_6,
      toanha_viettel_complex_tower_7,
      toanha_viettel_complex_tower_8,
      toanha_viettel_complex_tower_9,
      toanha_viettel_complex_tower_10,
      toanha_viettel_complex_tower_11,
      toanha_viettel_complex_tower_12,
      toanha_viettel_complex_tower_13,
      toanha_viettel_complex_tower_14,
      toanha_viettel_complex_tower_15,
      toanha_viettel_complex_tower_16,
      toanha_viettel_complex_tower_17,
      toanha_viettel_complex_tower_18,
      toanha_viettel_complex_tower_19,
      toanha_viettel_complex_tower_20,
      toanha_viettel_complex_tower_21,
      toanha_viettel_complex_tower_22,
      toanha_viettel_complex_tower_23,
      toanha_viettel_complex_tower_24,
      toanha_viettel_complex_tower_25,
      toanha_viettel_complex_tower_26,
      toanha_viettel_complex_tower_27,
      toanha_viettel_complex_tower_28,
      toanha_viettel_complex_tower_29,
      toanha_viettel_complex_tower_30,
      toanha_viettel_complex_tower_31,
      toanha_viettel_complex_tower_32,
      toanha_viettel_complex_tower_33,
      toanha_viettel_complex_tower_34,
      toanha_viettel_complex_tower_35,
    ],
  },
  {
    id: 24,
    title: "Toà Nhà VinaKin",
    text: "",
    projectname: "vinakin-builidng",
    location: "TP.HCM",
    client: "",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: ["architecture"],
    thumb: vinakin_building_1,
    img: [
      vinakin_building_1,
      vinakin_building_2,
      vinakin_building_3,
      vinakin_building_4,
      vinakin_building_5,
      vinakin_building_6,
      vinakin_building_7,
      vinakin_building_8,
      vinakin_building_9,
      vinakin_building_10,
      vinakin_building_11,
      vinakin_building_12,
      vinakin_building_13,
      vinakin_building_14,
      vinakin_building_15,
      vinakin_building_16,
      vinakin_building_17,
      vinakin_building_18,
      vinakin_building_19,
      vinakin_building_20,
      vinakin_building_21,
      vinakin_building_22,
      vinakin_building_23,
      vinakin_building_24,
      vinakin_building_25,
      vinakin_building_26,
      vinakin_building_27,
      vinakin_building_28,
      vinakin_building_29,
      vinakin_building_30,
      vinakin_building_31,
      vinakin_building_32,
      vinakin_building_33,
      vinakin_building_34,
      vinakin_building_35,
      vinakin_building_36,
      vinakin_building_37,
      vinakin_building_38,
      vinakin_building_39,
      vinakin_building_40,
      vinakin_building_41,
    ],
  },
  // {
  //   id: 25,
  //   title: "Nhà Phố Hóc Môn",
  //   text: "",
  //   projectname: "nha-pho-hoc-mon",
  //   location: "TP.HCM",
  //   client: "",
  //   executionTime: 2022,
  //   photo: "Giang Ho",
  //   hashtag: ["house"],
  //   thumb: nhapho_hocmon_1,
  //   img: [
  //     nhapho_hocmon_1,
  //     nhapho_hocmon_2,
  //     nhapho_hocmon_3,
  //     nhapho_hocmon_4,
  //     nhapho_hocmon_5,
  //     nhapho_hocmon_6,
  //     nhapho_hocmon_7,
  //     nhapho_hocmon_8,
  //     nhapho_hocmon_9,
  //     nhapho_hocmon_10,
  //     nhapho_hocmon_11,
  //     nhapho_hocmon_12,
  //     nhapho_hocmon_13,
  //     nhapho_hocmon_14,
  //     nhapho_hocmon_15,
  //     nhapho_hocmon_16,
  //     nhapho_hocmon_17,
  //     nhapho_hocmon_18,
  //     nhapho_hocmon_19,
  //     nhapho_hocmon_20,
  //   ],
  // },
  // {
  //   id: 26,
  //   title: "Biệt Thự ZeitGeist Nhà Bè",
  //   text: "Holm Design",
  //   projectname: "biet-thu-zeitgeist-nha-be",
  //   location: "TP.HCM",
  //   client: "Holm Design",
  //   executionTime: 2024,
  //   photo: "Giang Ho",
  //   hashtag: ["house", "productspace"],
  //   thumb: bietthu_zeitgeist_nhabe_3,
  //   img: [
  //     bietthu_zeitgeist_nhabe_1,
  //     bietthu_zeitgeist_nhabe_2,
  //     bietthu_zeitgeist_nhabe_3,
  //     bietthu_zeitgeist_nhabe_4,
  //     bietthu_zeitgeist_nhabe_5,
  //     bietthu_zeitgeist_nhabe_6,
  //     bietthu_zeitgeist_nhabe_7,
  //     bietthu_zeitgeist_nhabe_8,
  //     bietthu_zeitgeist_nhabe_9,
  //     bietthu_zeitgeist_nhabe_10,
  //     bietthu_zeitgeist_nhabe_11,
  //     bietthu_zeitgeist_nhabe_12,
  //     bietthu_zeitgeist_nhabe_13,
  //     bietthu_zeitgeist_nhabe_14,
  //     bietthu_zeitgeist_nhabe_15,
  //     bietthu_zeitgeist_nhabe_16,
  //     bietthu_zeitgeist_nhabe_17,
  //     bietthu_zeitgeist_nhabe_18,
  //     bietthu_zeitgeist_nhabe_19,
  //     bietthu_zeitgeist_nhabe_20,
  //     bietthu_zeitgeist_nhabe_21,
  //     bietthu_zeitgeist_nhabe_22,
  //     bietthu_zeitgeist_nhabe_23,
  //     bietthu_zeitgeist_nhabe_24,
  //     bietthu_zeitgeist_nhabe_25,
  //     bietthu_zeitgeist_nhabe_26,
  //     bietthu_zeitgeist_nhabe_27,
  //     bietthu_zeitgeist_nhabe_28,
  //     bietthu_zeitgeist_nhabe_29,
  //   ],
  // },
  // {
  //   id: 27,
  //   title: "Park Riverside Premium Villa",
  //   text: "Holm Design",
  //   projectname: "park-riverside-premium-villa",
  //   location: "TP.HCM",
  //   client: "Holm Design",
  //   executionTime: 2024,
  //   photo: "Giang Ho",
  //   hashtag: ["house"],
  //   thumb: park_riverside_premium_villa_1,
  //   img: [
  //     park_riverside_premium_villa_1,
  //     park_riverside_premium_villa_2,
  //     park_riverside_premium_villa_3,
  //     park_riverside_premium_villa_4,
  //     park_riverside_premium_villa_5,
  //     park_riverside_premium_villa_6,
  //     park_riverside_premium_villa_7,
  //     park_riverside_premium_villa_8,
  //     park_riverside_premium_villa_9,
  //     park_riverside_premium_villa_10,
  //     park_riverside_premium_villa_11,
  //     park_riverside_premium_villa_12,
  //     park_riverside_premium_villa_13,
  //     park_riverside_premium_villa_14,
  //     park_riverside_premium_villa_15,
  //     park_riverside_premium_villa_16,
  //     park_riverside_premium_villa_17,
  //     park_riverside_premium_villa_18,
  //     park_riverside_premium_villa_19,
  //     park_riverside_premium_villa_20,
  //     park_riverside_premium_villa_21,
  //     park_riverside_premium_villa_22,
  //     park_riverside_premium_villa_23,
  //     park_riverside_premium_villa_24,
  //     park_riverside_premium_villa_25,
  //     park_riverside_premium_villa_26,
  //     park_riverside_premium_villa_27,
  //     park_riverside_premium_villa_28,
  //     park_riverside_premium_villa_29,
  //     park_riverside_premium_villa_30,
  //   ],
  // },
  // {
  //   id: 28,
  //   title: "Căn Hộ Quận 12",
  //   text: "Holm Design",
  //   projectname: "can-ho-q12",
  //   location: "TP.HCM",
  //   client: "Holm Design",
  //   executionTime: 2024,
  //   photo: "Giang Ho",
  //   hashtag: ["house", "productspace"],
  //   thumb: canho_q12_31,
  //   img: [
  //     canho_q12_1,
  //     canho_q12_2,
  //     canho_q12_3,
  //     canho_q12_4,
  //     canho_q12_5,
  //     canho_q12_6,
  //     canho_q12_7,
  //     canho_q12_8,
  //     canho_q12_9,
  //     canho_q12_10,
  //     canho_q12_11,
  //     canho_q12_12,
  //     canho_q12_13,
  //     canho_q12_14,
  //     canho_q12_15,
  //     canho_q12_16,
  //     canho_q12_17,
  //     canho_q12_18,
  //     canho_q12_19,
  //     canho_q12_20,
  //     canho_q12_21,
  //     canho_q12_22,
  //     canho_q12_23,
  //     canho_q12_24,
  //     canho_q12_25,
  //     canho_q12_26,
  //     canho_q12_27,
  //     canho_q12_28,
  //     canho_q12_29,
  //     canho_q12_30,
  //     canho_q12_31,
  //     canho_q12_32,
  //     canho_q12_33,
  //     canho_q12_34,
  //     canho_q12_35,
  //     canho_q12_36,
  //     canho_q12_37,
  //     canho_q12_38,
  //     canho_q12_39,
  //     canho_q12_40,
  //     canho_q12_41,
  //     canho_q12_42,
  //     canho_q12_43,
  //     canho_q12_44,
  //     canho_q12_45,
  //     canho_q12_46,
  //     canho_q12_47,
  //     canho_q12_48,
  //     canho_q12_49,
  //     canho_q12_50,
  //     canho_q12_51,
  //     canho_q12_52,
  //     canho_q12_53,
  //     canho_q12_54,
  //     canho_q12_55,
  //     canho_q12_56,
  //     canho_q12_57,
  //     canho_q12_58,
  //     canho_q12_59,
  //     canho_q12_60,
  //     canho_q12_61,
  //     canho_q12_62,
  //     canho_q12_63,
  //     canho_q12_64,
  //     canho_q12_65,
  //     canho_q12_66,
  //     canho_q12_67,
  //   ],
  // },
  // {
  //   id: 29,
  //   title: "Căn Hộ Sài Gòn Pearl",
  //   text: "Holm Design",
  //   projectname: "can-ho-sai-gon-pearl",
  //   location: "TP.HCM",
  //   client: "Holm Design",
  //   executionTime: 2024,
  //   photo: "Giang Ho",
  //   hashtag: ["house"],
  //   thumb: canho_saigon_pearl_20,
  //   img: [
  //     canho_saigon_pearl_1,
  //     canho_saigon_pearl_2,
  //     canho_saigon_pearl_3,
  //     canho_saigon_pearl_4,
  //     canho_saigon_pearl_5,
  //     canho_saigon_pearl_6,
  //     canho_saigon_pearl_7,
  //     canho_saigon_pearl_8,
  //     canho_saigon_pearl_9,
  //     canho_saigon_pearl_10,
  //     canho_saigon_pearl_11,
  //     canho_saigon_pearl_12,
  //     canho_saigon_pearl_13,
  //     canho_saigon_pearl_14,
  //     canho_saigon_pearl_15,
  //     canho_saigon_pearl_16,
  //     canho_saigon_pearl_17,
  //     canho_saigon_pearl_18,
  //     canho_saigon_pearl_19,
  //     canho_saigon_pearl_20,
  //   ],
  // },
  // {
  //   id: 30,
  //   title: "Nhà Phố",
  //   text: "Holm Design",
  //   projectname: "nha-pho-holm-design",
  //   location: "TP.HCM",
  //   client: "Holm Design",
  //   executionTime: 2024,
  //   photo: "Giang Ho",
  //   hashtag: ["house"],
  //   thumb: nha_pho_holm_design_1,
  //   img: [
  //     nha_pho_holm_design_1,
  //     nha_pho_holm_design_2,
  //     nha_pho_holm_design_3,
  //     nha_pho_holm_design_4,
  //     nha_pho_holm_design_5,
  //     nha_pho_holm_design_6,
  //     nha_pho_holm_design_7,
  //     nha_pho_holm_design_8,
  //     nha_pho_holm_design_9,
  //     nha_pho_holm_design_10,
  //     nha_pho_holm_design_11,
  //     nha_pho_holm_design_12,
  //     nha_pho_holm_design_13,
  //     nha_pho_holm_design_14,
  //     nha_pho_holm_design_15,
  //     nha_pho_holm_design_16,
  //     nha_pho_holm_design_17,
  //     nha_pho_holm_design_18,
  //     nha_pho_holm_design_19,
  //     nha_pho_holm_design_20,
  //     nha_pho_holm_design_21,
  //     nha_pho_holm_design_22,
  //     nha_pho_holm_design_23,
  //     nha_pho_holm_design_24,
  //     nha_pho_holm_design_25,
  //     nha_pho_holm_design_26,
  //     nha_pho_holm_design_27,
  //     nha_pho_holm_design_28,
  //     nha_pho_holm_design_29,
  //     nha_pho_holm_design_30,
  //   ],
  // },
  // {
  //   id: 31,
  //   title: "Căn Hộ Gateway",
  //   text: "PAU Design",
  //   projectname: "can-ho-gateway",
  //   location: "Vũng Tàu",
  //   client: "PAU Design",
  //   executionTime: 2024,
  //   photo: "Giang Ho",
  //   hashtag: ["house"],
  //   thumb: canho_gateway_vungtau_1,
  //   img: [
  //     canho_gateway_vungtau_1,
  //     canho_gateway_vungtau_2,
  //     canho_gateway_vungtau_3,
  //     canho_gateway_vungtau_4,
  //     canho_gateway_vungtau_5,
  //     canho_gateway_vungtau_6,
  //     canho_gateway_vungtau_7,
  //     canho_gateway_vungtau_8,
  //     canho_gateway_vungtau_9,
  //     canho_gateway_vungtau_10,
  //     canho_gateway_vungtau_11,
  //     canho_gateway_vungtau_12,
  //     canho_gateway_vungtau_13,
  //     canho_gateway_vungtau_14,
  //     canho_gateway_vungtau_15,
  //     canho_gateway_vungtau_16,
  //     canho_gateway_vungtau_17,
  //     canho_gateway_vungtau_18,
  //     canho_gateway_vungtau_19,
  //     canho_gateway_vungtau_20,
  //     canho_gateway_vungtau_21,
  //     canho_gateway_vungtau_22,
  //     canho_gateway_vungtau_23,
  //     canho_gateway_vungtau_24,
  //     canho_gateway_vungtau_25,
  //   ],
  // },
  // {
  //   id: 32,
  //   title: "Nhà Phố Thủ Dầu Một",
  //   text: "PAU Design",
  //   projectname: "nha-pho-thu-dau-mot",
  //   location: "Bình Dương",
  //   client: "PAU Design",
  //   executionTime: 2024,
  //   photo: "Giang Ho",
  //   hashtag: ["house"],
  //   thumb: nhapho_thudaumot_1,
  //   img: [
  //     nhapho_thudaumot_1,
  //     nhapho_thudaumot_2,
  //     nhapho_thudaumot_3,
  //     nhapho_thudaumot_4,
  //     nhapho_thudaumot_5,
  //     nhapho_thudaumot_6,
  //     nhapho_thudaumot_7,
  //     nhapho_thudaumot_8,
  //     nhapho_thudaumot_9,
  //     nhapho_thudaumot_10,
  //     nhapho_thudaumot_11,
  //     nhapho_thudaumot_12,
  //     nhapho_thudaumot_13,
  //     nhapho_thudaumot_14,
  //     nhapho_thudaumot_15,
  //     nhapho_thudaumot_16,
  //     nhapho_thudaumot_17,
  //     nhapho_thudaumot_18,
  //     nhapho_thudaumot_19,
  //     nhapho_thudaumot_20,
  //     nhapho_thudaumot_21,
  //     nhapho_thudaumot_22,
  //     nhapho_thudaumot_23,
  //     nhapho_thudaumot_24,
  //     nhapho_thudaumot_25,
  //     nhapho_thudaumot_26,
  //     nhapho_thudaumot_27,
  //     nhapho_thudaumot_28,
  //     nhapho_thudaumot_29,
  //   ],
  // },
  // {
  //   id: 33,
  //   title: "Biệt Thự",
  //   text: "",
  //   projectname: "biet-thu",
  //   location: "",
  //   client: "",
  //   executionTime: 2024,
  //   photo: "Giang Ho",
  //   hashtag: ["house"],
  //   thumb: bietthu_longan_4,
  //   img: [
  //     bietthu_longan_4,
  //     bietthu_longan_5,
  //     bietthu_longan_7,
  //     bietthu_longan_8,
  //     bietthu_longan_9,
  //     bietthu_longan_10,
  //     bietthu_longan_12,
  //     bietthu_longan_13,
  //     bietthu_longan_14,
  //     bietthu_longan_15,
  //     bietthu_longan_16,
  //     bietthu_longan_17,
  //     bietthu_longan_18,
  //     bietthu_longan_19,
  //     bietthu_longan_20,
  //     bietthu_longan_21,
  //     bietthu_longan_22,
  //     bietthu_longan_23,
  //     bietthu_longan_24,
  //   ],
  // },
  {
    id: 34,
    title: "Trường Mầm Non Riverside Residence Quận 7",
    text: "Thiên Phố",
    projectname: "truong-mam-non-riverside-residence-q7",
    location: "TP.HCM",
    client: "Thiên Phố",
    executionTime: 2024,
    photo: "Giang Ho",
    hashtag: ["school"],
    thumb: mam_non_riverside_residence_q7_13,
    img: [
      mam_non_riverside_residence_q7_1,
      mam_non_riverside_residence_q7_2,
      mam_non_riverside_residence_q7_3,
      mam_non_riverside_residence_q7_4,
      mam_non_riverside_residence_q7_5,
      mam_non_riverside_residence_q7_6,
      mam_non_riverside_residence_q7_7,
      mam_non_riverside_residence_q7_8,
      mam_non_riverside_residence_q7_9,
      mam_non_riverside_residence_q7_10,
      mam_non_riverside_residence_q7_11,
      mam_non_riverside_residence_q7_12,
      mam_non_riverside_residence_q7_13,
      mam_non_riverside_residence_q7_14,
      mam_non_riverside_residence_q7_15,
      mam_non_riverside_residence_q7_16,
      mam_non_riverside_residence_q7_17,
      mam_non_riverside_residence_q7_18,
      mam_non_riverside_residence_q7_19,
      mam_non_riverside_residence_q7_20,
      mam_non_riverside_residence_q7_21,
      mam_non_riverside_residence_q7_22,
      mam_non_riverside_residence_q7_23,
      mam_non_riverside_residence_q7_24,
      mam_non_riverside_residence_q7_25,
      mam_non_riverside_residence_q7_26,
      mam_non_riverside_residence_q7_27,
      mam_non_riverside_residence_q7_28,
      mam_non_riverside_residence_q7_29,
      mam_non_riverside_residence_q7_30,
      mam_non_riverside_residence_q7_31,
      mam_non_riverside_residence_q7_32,
      mam_non_riverside_residence_q7_33,
      mam_non_riverside_residence_q7_34,
      mam_non_riverside_residence_q7_35,
      mam_non_riverside_residence_q7_36,
      mam_non_riverside_residence_q7_37,
      mam_non_riverside_residence_q7_38,
      mam_non_riverside_residence_q7_39,
      mam_non_riverside_residence_q7_40,
      mam_non_riverside_residence_q7_41,
      mam_non_riverside_residence_q7_42,
      mam_non_riverside_residence_q7_43,
      mam_non_riverside_residence_q7_44,
      mam_non_riverside_residence_q7_45,
      mam_non_riverside_residence_q7_46,
      mam_non_riverside_residence_q7_47,
      mam_non_riverside_residence_q7_48,
      mam_non_riverside_residence_q7_49,
      mam_non_riverside_residence_q7_50,
      mam_non_riverside_residence_q7_51,
      mam_non_riverside_residence_q7_52,
      mam_non_riverside_residence_q7_53,
      mam_non_riverside_residence_q7_54,
      mam_non_riverside_residence_q7_55,
      mam_non_riverside_residence_q7_56,
      mam_non_riverside_residence_q7_57,
      mam_non_riverside_residence_q7_58,
      mam_non_riverside_residence_q7_59,
      mam_non_riverside_residence_q7_60,
      mam_non_riverside_residence_q7_61,
      mam_non_riverside_residence_q7_62,
      mam_non_riverside_residence_q7_63,
      mam_non_riverside_residence_q7_64,
      mam_non_riverside_residence_q7_65,
      mam_non_riverside_residence_q7_66,
      mam_non_riverside_residence_q7_67,
      mam_non_riverside_residence_q7_68,
      mam_non_riverside_residence_q7_69,
      mam_non_riverside_residence_q7_70,
      mam_non_riverside_residence_q7_71,
      mam_non_riverside_residence_q7_72,
      mam_non_riverside_residence_q7_73,
      mam_non_riverside_residence_q7_74,
      mam_non_riverside_residence_q7_75,
      mam_non_riverside_residence_q7_76,
      mam_non_riverside_residence_q7_77,
      mam_non_riverside_residence_q7_78,
      mam_non_riverside_residence_q7_79,
      mam_non_riverside_residence_q7_80,
      mam_non_riverside_residence_q7_81,
      mam_non_riverside_residence_q7_82,
      mam_non_riverside_residence_q7_83,
      mam_non_riverside_residence_q7_84,
      mam_non_riverside_residence_q7_85,
      mam_non_riverside_residence_q7_86,
      mam_non_riverside_residence_q7_87,
      mam_non_riverside_residence_q7_88,
      mam_non_riverside_residence_q7_89,
      mam_non_riverside_residence_q7_90,
      mam_non_riverside_residence_q7_91,
      mam_non_riverside_residence_q7_92,
      mam_non_riverside_residence_q7_93,
      mam_non_riverside_residence_q7_94,
      mam_non_riverside_residence_q7_95,
      mam_non_riverside_residence_q7_96,
      mam_non_riverside_residence_q7_97,
      mam_non_riverside_residence_q7_98,
      mam_non_riverside_residence_q7_99,
      mam_non_riverside_residence_q7_100,
    ],
  },
  // {
  //   id: 35,
  //   title: "Căn Hộ The View",
  //   text: "Thiên Phố",
  //   projectname: "can-ho-the-view",
  //   location: "TP.HCM",
  //   client: "Thiên Phố",
  //   executionTime: 2024,
  //   photo: "Giang Ho",
  //   hashtag: ["house"],
  //   thumb: canho_the_view_10,
  //   img: [
  //     canho_the_view_1,
  //     canho_the_view_2,
  //     canho_the_view_3,
  //     canho_the_view_4,
  //     canho_the_view_5,
  //     canho_the_view_6,
  //     canho_the_view_7,
  //     canho_the_view_8,
  //     canho_the_view_9,
  //     canho_the_view_10,
  //     canho_the_view_11,
  //     canho_the_view_12,
  //     canho_the_view_13,
  //     canho_the_view_14,
  //     canho_the_view_15,
  //     canho_the_view_16,
  //     canho_the_view_17,
  //     canho_the_view_18,
  //     canho_the_view_19,
  //     canho_the_view_20,
  //     canho_the_view_21,
  //     canho_the_view_22,
  //     canho_the_view_23,
  //     canho_the_view_24,
  //     canho_the_view_25,
  //   ],
  // },
];

const Body = () => {
  const servicesState = useSelector((state) => state.services);
  const { activeFilter } = servicesState;
  // const [activeFilter, setActiveFilter] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 6;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filteredProjects = activeFilter
    ? ServicesPhotographBodyList.filter((project) =>
        project.hashtag.includes(activeFilter)
      )
    : ServicesPhotographBodyList;

  const startIndex = (currentPage - 1) * projectsPerPage;
  const paginatedProjects = filteredProjects.slice(
    startIndex,
    startIndex + projectsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleProjectClick = (project) => {
    const projectHashtag = project.hashtag[0]; // Assuming each project has at least one hashtag
    const projectName = project.projectname;
    // const projectName = project.title.toLowerCase().replace(/\s+/g, '-'); // Convert title to URL-friendly format
    dispatch(setSelectedProject(project));
    console.log("project", project);
    navigate(`/services/photograph/${projectHashtag}/${projectName}`);
  };

  return (
    <section className="Services_Photograph_Body_wrapper wrapper flex flex-col items-center mx-auto">
      <div className="mb-10 md:mb-20 px-2 py-2 md:px-20 rounded-2xl shadow-lg bg-gray-100 dark:bg-gray-900">
        {filter.map((f) => (
          <button
            key={f.id}
            className={`m-0.5 md:m-1 p-1 border-none ${
              activeFilter === f.hashtag
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-black dark:bg-gray-800"
            } rounded hover:text-black hover:bg-gray-400`}
            onClick={() => {
              dispatch(setActiveFilter(f.hashtag));
              setCurrentPage(1); // Reset to first page on filter change
            }}
          >
            <span className="text-xs md:text-sm p-2">{f.text}</span>
          </button>
        ))}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 w-full px-0 lg:px-20">
        {paginatedProjects.map((project) => (
          <div
            key={project.id}
            className="bg-gray-100 dark:bg-gray-800 p-4 rounded-lg shadow-lg cursor-pointer group"
            onClick={() => handleProjectClick(project)}
          >
            <div className="h-60 w-full relative overflow-hidden">
              <img
                src={project.thumb}
                alt={project.title}
                className="absolute w-full h-full object-cover rounded group-hover:scale-110 duration-500 transition-all ease-in-out"
              />
            </div>

            <h3 className="mt-4 text-lg font-bold uppercase text-black dark:text-white">
              {project.title}
            </h3>
            <div className="mt-2">
              {project.hashtag.map((tag, index) => {
                const filterText = filter.find((f) => f.hashtag === tag)?.text;
                return (
                  <span
                    key={index}
                    className="inline-block bg-gray-200 text-gray-700 text-xs px-2 py-1 mr-1 mb-1 md:mr-2 md:mb-2 rounded-full"
                  >
                    {filterText}
                  </span>
                );
              })}
            </div>
          </div>
        ))}
      </div>

      <Pagination
        className="mt-20 dark:bg-white"
        align="center"
        defaultCurrent={1}
        current={currentPage}
        pageSize={projectsPerPage}
        total={filteredProjects.length}
        onChange={handlePageChange}
      />
    </section>
  );
};

export default Body;
