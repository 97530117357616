import React, { useEffect } from "react";
import "./Verify.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Verify = ({ checkVerify, setCheckVerify }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [attemptedLogin, setAttemptedLogin] = useState(false); // State để theo dõi nếu người dùng đã thử đăng nhập

  const handleLogin = () => {
    const envUsernames = [
      process.env.REACT_APP_USERNAME_LOGIN_WEDEVOTO_1,
      process.env.REACT_APP_USERNAME_LOGIN_WEDEVOTO_2,
      process.env.REACT_APP_USERNAME_LOGIN_WEDEVOTO_3,
      // Add more if needed
    ];

    const envPasswords = [
      process.env.REACT_APP_PASSWORD_LOGIN_WEDEVOTO_1,
      process.env.REACT_APP_PASSWORD_LOGIN_WEDEVOTO_2,
      process.env.REACT_APP_PASSWORD_LOGIN_WEDEVOTO_3,
      // Add more if needed
    ];

    let isAuthenticated = false;

    for (let i = 0; i < envUsernames.length; i++) {
      if (username === envUsernames[i] && password === envPasswords[i]) {
        isAuthenticated = true;
        break;
      }
    }
    
    setCheckVerify(isAuthenticated);
    setAttemptedLogin(true); // Đánh dấu rằng người dùng đã thử đăng nhập

  };

  useEffect(() => {
    // Chỉ thực hiện kiểm tra nếu người dùng đã thử đăng nhập
    if (attemptedLogin) {
      if (checkVerify) {
        navigate("/"); // Điều hướng đến trang gốc
      } else {
        alert("Bạn Đã Nhập Sai, Vui Lòng Nhập Lại");
      }
    }

  }, [checkVerify, attemptedLogin, navigate]);

  return (
    <section className="Verify_wrapper absolute w-full h-full flex flex-col justify-center items-center">
      <div className="Verify_form flex flex-col justify-around items-center rounded-xl p-5 bg-white w-[450px]">
        <div className="title uppercase font-bold text-black py-5 text-xl">Đăng Nhập</div>
        {/* <p className="message">Vui Lòng Đang Nhập Để Tiếp Tục</p> */}
        <div className="inputs flex flex-col ">
          <form action=""></form>
          <input
            onChange={(e) => setUsername(e.target.value)}
            id="input1"
            type="text"
            // maxLength="6"
            placeholder="Username"
          />
          
          <input
            onChange={(e) => setPassword(e.target.value)}
            id="input2"
            type="password"
            // maxLength="6"
            placeholder="Password"
          />
        </div>
        <button
          type="submit"
          className="action"
          onClick={() => handleLogin()}
        >
          Xác Nhận
        </button>
      </div>
    </section>
  );
};

export default Verify;
