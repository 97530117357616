import { createSlice } from "@reduxjs/toolkit";
import { ServicesPhotographBodyList } from "../../components/Services/Photograph/Body";

const initialState = {
  selectedProject: null,
  activeFilter: null,
};

const ServicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    setSelectedProject(state, action) {
      state.selectedProject = action.payload;
    },
    setActiveFilter(state, action) {
      state.activeFilter = action.payload;
    },
  },

  // option 2 for search hashtag and projectName
  // Example: http://localhost:3000/services/photograph/inducstrialarea/du-an-3
  fetchProjectByParams(state, action) {
    const { hashtag, projectName } = action.payload;
    const foundProject = ServicesPhotographBodyList.find(
      (p) =>
        p.hashtag.includes(hashtag) &&
        p.projectname === projectName
    );
    state.selectedProject = foundProject;
  },
});

export default ServicesSlice.reducer;
export const { setSelectedProject, fetchProjectByParams, setActiveFilter } = ServicesSlice.actions;
