import { configureStore } from "@reduxjs/toolkit";
import LanguageReducer from "./features/LanguageSlice";
import NavbarReducer from "./features/NavbarSlice";
import ThemeReducer from './features/ThemeSlice';
import ServicesReducer from './features/ServicesSlice';

const store = configureStore({
  reducer: {
    language: LanguageReducer,
    nav: NavbarReducer,
    theme: ThemeReducer,
    services: ServicesReducer,
  },
});

export default store;
