import React from "react";
import "./LoadingFallback.scss";

const LoadingFallback = () => {
  return (
    <section className="LoadingFallback_wrapper">
      <div className="loader">
        <div className="loader-inner">
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
        </div>
      </div>
      <span className="text-white abc">WEDEVOTO</span>

    </section>
  );
};

export default LoadingFallback;
